import Keycloak from 'keycloak-js'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import { AuthState } from '../model/enums'

type TenantInfo = {
  name: string
  realm: string
  keycloakUri: string
}

type UserInfoType = {
  userId: string
  firstName: string
  lastName: string
  email: string
  fullName: string
  roles: string[]
}

type AuthStoreType = {
  tenant?: TenantInfo
  accessToken?: string
  refreshToken?: string
  idToken?: string
  authState: AuthState
  refreshTokenTimerId?: NodeJS.Timeout
  userInfo: UserInfoType
  isTransparencyChecked?: boolean
  keycloak?: Keycloak
}

const initialState: AuthStoreType = {
  authState: AuthState.UNKNOWN,
  userInfo: {
    email: '',
    firstName: '',
    fullName: '',
    lastName: '',
    userId: '',
    roles: []
  }
}

const useStore = createWithEqualityFn<AuthStoreType>(() => initialState, shallow)

export const authStore = {
  setAuthState: (authState: AuthState): void => {
    useStore.setState({ authState })
  },
  getAuthState: (): AuthState => {
    return useStore.getState().authState
  },
  saveIdToken: (idToken: string | undefined): void => {
    useStore.setState({ idToken })
  },
  saveAccessToken: (accessToken: string | undefined): void => {
    useStore.setState({ accessToken })
  },
  saveRefreshToken: (refreshToken: string | undefined): void => {
    useStore.setState({ refreshToken })
  },
  isAuthorized: (): boolean => {
    return (
      useStore.getState().authState === AuthState.AUTHENTICATED ||
      useStore.getState().authState === AuthState.AUTHENTICATED_QR
    )
  },
  setRefreshTokenTimerId: (refreshTokenTimerId: NodeJS.Timeout): void => {
    useStore.setState({ refreshTokenTimerId })
  },
  getRefreshTokenTimerId: (): NodeJS.Timeout | undefined => {
    return useStore.getState().refreshTokenTimerId
  },
  clearRefreshTokenTimerId: (): void => {
    useStore.setState({ refreshTokenTimerId: undefined })
  },
  setUserInfo: (userInfo: UserInfoType): void => {
    useStore.setState({ userInfo })
  },
  setIsTransparencyChecked: (isTransparencyChecked: boolean): void => {
    useStore.setState({ isTransparencyChecked })
  },

  clearStore: (): void => {
    useStore.setState(initialState, true)
  },
  useStore
}
