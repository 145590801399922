import { Trans } from 'react-i18next'
import { useKeycloak } from '@react-keycloak/web'

import { ImageContent } from '@app/assets/ImageContent'
import { Button, Heading, Label } from '@app/components'
import { useTranslation } from '@app/locales'

export const IncorrectRole = () => {
  const { t } = useTranslation(['common', 'error', 'settings'])
  const { keycloak } = useKeycloak()

  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <div className="flex max-w-[500px] flex-col items-center justify-center gap-4 text-center">
        <img src={ImageContent.common.insufficientRole} width={400} className="-mt-8" />

        <Heading size="l">{t('insufficientRole.title')}</Heading>

        <div>
          <Trans
            ns={'error'}
            parent={Label}
            className="!text-xl"
            i18nKey="insufficientRole.description"
            components={[<span className="text-neutral50" />]}
          />
        </div>

        <Button onClick={() => keycloak.logout()}>{t('logout.title')}</Button>
      </div>
    </div>
  )
}
