import { useLoader } from '@react-three/fiber'
import * as THREE from 'three'

type PNGModelProps = {
  position: THREE.Vector3Tuple
  rotation: THREE.Euler
  path: string
  surfaceOffset: number
  width: number
  height: number
}

export const PNGModel = ({ position, rotation, path, surfaceOffset, width, height }: PNGModelProps) => {
  const texture = useLoader(THREE.TextureLoader, `/learningPath/images/${path}`)

  // planeGeometry is a flat 2D surface, and its center lies at the origin of the geometry,
  // we need to calculate the normal vector (the vector pointing directly outwards from the sphere’s surface
  const adjustedPosition = new THREE.Vector3(...position)
  const normal = adjustedPosition.clone().normalize()
  adjustedPosition.add(normal.multiplyScalar(surfaceOffset))

  // add a slight Z-axis rotation to face the camera more
  const adjustedRotation = rotation.clone()
  const xAdjustment = Math.PI / 4 // tilt backward by 30 degrees
  adjustedRotation.x -= xAdjustment

  return (
    <mesh receiveShadow castShadow position={adjustedPosition.toArray()} rotation={adjustedRotation}>
      <planeGeometry args={[width, height]} />
      <meshStandardMaterial
        map={texture}
        transparent={true}
        side={THREE.DoubleSide} // ensures both sides render to make the shadows visible
      />
    </mesh>
  )
}
