import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { motion, useMotionValue, useTransform } from 'framer-motion'

import { useVisibleState } from '@foxino/components-common'

import { ImageContent } from '@app/assets/ImageContent'
import { Pages } from '@app/config/router/Pages'
import Constants from '@app/constants'

import { MainCanvas, StartLessonDialog } from '../../components'
import { MAX_ROTATION_X, MIN_ROTATION_X } from '../../constants'
import { Banner } from './Banner'

import './LearningPathPage.styles.css'

export const LearningPathPage = () => {
  const [isExitLessonDialogVisible, showStartLessonDialog, closeStartLessonDialog] = useVisibleState()

  const navigate = useNavigate()

  useEffect(() => {
    if (!Constants.ENABLE_LEARNING_PATH) {
      navigate(Pages.DASHBOARD_TASKS)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const scrollPosition = useMotionValue(0)
  const cloudY_1 = useTransform(
    scrollPosition,
    // map scrollPosition from these values:
    [MIN_ROTATION_X, MAX_ROTATION_X],
    // into these scale values:
    [0, -210]
  )
  const cloudY_2 = useTransform(
    scrollPosition,
    // map scrollPosition from these values:
    [MIN_ROTATION_X, MAX_ROTATION_X],
    // into these scale values:
    [0, -(150 / 2)]
  )

  const handleOnAnchorClick = useCallback(() => {
    showStartLessonDialog()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnCloseClick = () => {
    closeStartLessonDialog()
  }

  const handleOnLessonStart = () => {
    console.info('start lesson')
  }

  return (
    <>
      <StartLessonDialog
        isDialogVisible={isExitLessonDialogVisible}
        onClose={handleOnCloseClick}
        onLessonStart={handleOnLessonStart}
      />

      <div className="relative h-screen w-full flex-col overflow-hidden">
        <img className="absolute top-0 h-[50%] w-full" src={ImageContent.learningPath.sky} alt="sky" />
        <img className="absolute top-[200px] w-full" src={ImageContent.learningPath.landscape} alt="landscape" />
        <motion.img
          style={{ y: cloudY_1 }}
          className="absolute right-[10px] top-[150px] z-10 w-[60px]"
          src={ImageContent.learningPath.planet1}
          alt="planet1"
        />
        <motion.img
          style={{ y: cloudY_2 }}
          className="absolute right-[100px] top-[210px] z-10 w-[60px]"
          src={ImageContent.learningPath.planet2}
          alt="planet2"
        />
        <img
          className="learningPath-cloud-animation1 pointer-events-none absolute left-[0px] top-[130px] z-10"
          src={ImageContent.learningPath.cloud1}
          alt="cloud1"
        />
        <img
          className="learningPath-cloud-animation2 pointer-events-none absolute right-[10px] top-[70px] z-10"
          src={ImageContent.learningPath.cloud2}
          alt="cloud2"
        />

        <div className="absolute top-5 z-20 w-full">
          <Banner />
        </div>

        <MainCanvas onAnchorClick={handleOnAnchorClick} scrollPosition={scrollPosition} />
      </div>
    </>
  )
}

export default LearningPathPage
