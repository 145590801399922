import { ReactElement, ReactNode, useEffect } from 'react'
import { useKeycloak } from '@react-keycloak/web'

import { useTimeout } from '@foxino/components-common'

import { LoaderWrapper } from '@app/components'

import { tokenStore } from '../store/tokenStore'

type Props = {
  children: ReactNode | ReactNode[] | null
}

const LOGOUT_TIMER = 1_000

export const AuthProvider = ({ children }: Props): ReactElement | null => {
  const { keycloak } = useKeycloak()

  const [callLogoutTimer, isWaiting, clearLogoutTimer] = useTimeout(LOGOUT_TIMER)

  const isLoggedWithQR = tokenStore.useStore((store) => store.isLoggedWithQR)

  // Logout user when keycloak is initialized, but not authenticated
  // This is edge case where session needs to be cleared and logged in again
  useEffect(() => {
    if (!keycloak.authenticated && !isLoggedWithQR) {
      if (isWaiting) {
        callLogoutTimer(() => keycloak.login())
      }
    } else {
      clearLogoutTimer()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWaiting, keycloak.authenticated])

  if (!keycloak.authenticated && !isLoggedWithQR) {
    return (
      <div className="h-[100vh] w-full">
        <LoaderWrapper loading className="animate-spin" />
      </div>
    )
  }

  return <>{children}</>
}
