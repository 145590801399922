import { ReactElement, ReactNode, useEffect } from 'react'
import { useKeycloak } from '@react-keycloak/web'

import { Loader } from '@foxino/components-web'

import { UserRole } from '@app/data'
import { useAddRoleAndClassroom } from '@app/modules/settings/data'

import { IncorrectRole } from '../components'
import { authStore } from '../store/authStore'
import { tokenStore } from '../store/tokenStore'

type Props = {
  children: ReactNode | ReactNode[] | null
}

export const RoleProvider = ({ children }: Props): ReactElement | null => {
  const userInfo = authStore.useStore((store) => store.userInfo)
  const { addUserRole, pending, addMeToClassroom } = useAddRoleAndClassroom()
  const { keycloak } = useKeycloak()
  const newRegistrationData = tokenStore.useStore((store) => store.newRegistrationData)

  const { roles } = userInfo || {}

  useEffect(() => {
    const addRole = async () => {
      const success = await addUserRole(UserRole.STUDENT)

      if (success) {
        await keycloak.updateToken(3600)
      }
    }

    if (roles && !roles.includes(UserRole.STUDENT.toLocaleLowerCase())) {
      addRole()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles.length])

  useEffect(() => {
    if (newRegistrationData && roles.length > 0 && roles.includes(UserRole.STUDENT.toLocaleLowerCase())) {
      const addNewClass = async () => {
        await addMeToClassroom(newRegistrationData.classroomId, newRegistrationData.classroomLoginCode)

        tokenStore.clearRegistrationData()
      }

      addNewClass()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newRegistrationData, roles])

  if (pending) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Loader className="fill-neutral50" />
      </div>
    )
  }

  if (roles && roles.length > 0 && !roles.includes(UserRole.STUDENT.toLocaleLowerCase())) {
    return <IncorrectRole />
  }

  return <>{children}</>
}
