import React, { ReactElement, SVGProps } from 'react'

export const EnglishFlagIcon = React.forwardRef(
  ({ height = 16, width = 16, ...props }: SVGProps<SVGSVGElement>, ref: React.Ref<SVGSVGElement>): ReactElement => {
    return (
      <svg style={props.style} ref={ref} width={width} height={height} {...props} viewBox="0 0 24 16" fill="none">
        <g clipPath="url(#clip0_1780_1152)">
          <g clipPath="url(#clip1_1780_1152)">
            <rect width="24" height="16" fill="#1A47B8" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.55311 0H0V2.66667L21.433 16L24 16V13.3333L2.55311 0Z"
              fill="white"
            />
            <path d="M0.851541 0L24 14.4378V16H23.1684L0 1.54726V0H0.851541Z" fill="#F93939" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M21.7143 0H24V2.66667C24 2.66667 9.15406 11.5499 2.28571 16H0V13.3333L21.7143 0Z"
              fill="white"
            />
            <path d="M24 0H23.2249L0 14.4502V16H0.851541L24 1.55895V0Z" fill="#F93939" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.72809 0H15.2919V4.93527H24V11.0615H15.2919V16H8.72809V11.0615H0V4.93527H8.72809V0Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.1053 0H13.8947V6.15385H24V9.84615H13.8947V16H10.1053V9.84615H0V6.15385H10.1053V0Z"
              fill="#F93939"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_1780_1152">
            <rect width="24" height="16" rx="2" fill="white" />
          </clipPath>
          <clipPath id="clip1_1780_1152">
            <rect width="24" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    )
  }
)
