import { memo, useCallback, useRef, useState } from 'react'
import { OrbitControls } from '@react-three/drei'
import { Canvas } from '@react-three/fiber'
import { MotionValue } from 'framer-motion'
import * as THREE from 'three'

import { MAIN_PATH_AMPLITUDE, MAIN_PATH_FREQUENCY, SPHERE_RADIUS, TOTAL_NUMBER_OF_ANCHORS } from '../constants'
import { AnchorPosition } from '../types'
import { calculateRotationFromNormalVector } from '../utils'
import { RotatingGroup } from './RotatingGroup'

type MainCanvasProps = {
  onAnchorClick: () => void
  scrollPosition: MotionValue<number>
}

export const MainCanvas = memo(({ onAnchorClick, scrollPosition }: MainCanvasProps) => {
  const [showDebugControls, setShowDebugControls] = useState(false)
  const directionalLightRef = useRef<THREE.DirectionalLight>(null)

  const anchorPositions: AnchorPosition[] = []

  // instead of object creation in loop (because is expensive) we create a single object Vector3 instead and use vector.set()
  const tempPosition = new THREE.Vector3(0, 0, 0)
  const tempSphericalPosition = new THREE.Vector3(0, 0, 0)

  // generate anchor positions on the surface of the sphere along the vertical equator
  for (let i = 0; i < TOTAL_NUMBER_OF_ANCHORS; i++) {
    const phi = (i / TOTAL_NUMBER_OF_ANCHORS) * 2 * Math.PI

    const x = MAIN_PATH_AMPLITUDE * Math.sin(MAIN_PATH_FREQUENCY * phi) // apply the sine wave to x
    const y = SPHERE_RADIUS * Math.cos(phi)
    const z = SPHERE_RADIUS * Math.sin(phi)

    // save the position with the sine wave applied
    tempPosition.set(x, y, z)

    // normalize the position to ensure it's on the sphere's surface
    tempPosition.normalize().multiplyScalar(SPHERE_RADIUS)

    // for the rotation calculation, instead of using the position’s normal, calculate the normal vector based on the `unperturbed spherical position` (ignoring the sinusoidal x variation).
    tempSphericalPosition.set(0, y, z)
    const normalizedVector = tempSphericalPosition.clone().normalize()
    const rotation = calculateRotationFromNormalVector(normalizedVector)

    // store the perturbed position (with x variation) with correct rotation
    anchorPositions.push({
      position: tempPosition.toArray(),
      rotation
    })
  }

  const setDirectionalLightRef = useCallback((light: THREE.DirectionalLight | null) => {
    if (light) {
      // NOTE: set to true for debugging
      setShowDebugControls(false)
      // @ts-ignore
      directionalLightRef.current = light
    }
  }, [])

  return (
    <div className="relative z-30 flex h-full w-full flex-col">
      <Canvas camera={{ position: [0, 0, 70], fov: 50 }} shadows>
        <ambientLight color="white" intensity={1} />
        <hemisphereLight
        // color={new THREE.Color(0x222222)} // Darker sky color
        // groundColor={new THREE.Color(0xffffff)} // Brighter ground color
        // intensity={1}
        />

        <directionalLight
          ref={setDirectionalLightRef}
          position={[25, 30, 0]}
          intensity={2}
          castShadow
          shadow-camera-near={1} // objects closer than this distance to the camera are not rendered in shadows (default 0.5)
          shadow-camera-far={500} // objects farther than this distance from the camera are not rendered in shadows (default 500)
          shadow-camera-left={50} // orthographic bounds of the shadow camera
          shadow-camera-right={-50} // orthographic bounds of the shadow camera
          shadow-camera-top={10} // orthographic bounds of the shadow camera
          shadow-camera-bottom={-50} // orthographic bounds of the shadow camera
          shadow-bias={0.0001} // prevent shadow artifacts
        />

        {/* just for testing purpose */}
        {showDebugControls && <OrbitControls />}
        {showDebugControls && directionalLightRef.current && (
          <>
            <directionalLightHelper args={[directionalLightRef.current, 5]} />
            <cameraHelper args={[directionalLightRef.current.shadow.camera]} />
          </>
        )}

        <RotatingGroup
          anchorPositions={anchorPositions}
          onAnchorClick={onAnchorClick}
          scrollPosition={scrollPosition}
        />
      </Canvas>
    </div>
  )
})
