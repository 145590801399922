import dayjs from 'dayjs'

import { ImageContent } from '@app/assets/ImageContent'
import { SvgContent } from '@app/assets/SvgContent'
import { LearningAssignmentType, LearningExecutionState } from '@app/data'
import { useTranslation } from '@app/locales'
import { LearningExecutionProgressBar } from '@app/modules/common'
import { subjectToFlagRoundIcon } from '@app/utils/iconUtils'

import { LearningExecutionType } from '../model/types'

type Props = {
  isOdd: boolean
  task: LearningExecutionType
  onInProgressTaskClick?: (task: LearningExecutionType, type: string) => void
  onFinishedTaskClick?: (task: LearningExecutionType, type: string) => void
}

export const TaskCardSmall = ({ isOdd, task, onInProgressTaskClick, onFinishedTaskClick }: Props) => {
  const { t } = useTranslation('dashboard')

  const isInProgress = task.state === LearningExecutionState.IN_PROGRESS
  const isFinished = task.state === LearningExecutionState.FINISHED
  const isExpired = task.state === LearningExecutionState.EXPIRED

  const isTest = task.learningAssignment.type === LearningAssignmentType.TEST

  const handleOnPlayClick = () => {
    if (isInProgress) {
      onInProgressTaskClick?.(task, isOdd ? 'odd' : 'even')
    } else if (isFinished) {
      onFinishedTaskClick?.(task, isOdd ? 'odd' : 'even')
    }
  }

  const LanguageFlag = subjectToFlagRoundIcon[task.learningAssignment.subject]

  const endDate = task.learningAssignment.endDateAndTime
  const endDateIsLessThan1Day = endDate && dayjs(endDate).diff(dayjs(), 'hour') <= 24

  const ResultIcon =
    endDateIsLessThan1Day && !isInProgress && !isFinished && !isExpired ? (
      <object className="h-8 w-10 object-contain" type="image/svg+xml" data={SvgContent.tasks.alertTriangle} />
    ) : isTest && isFinished ? (
      <span
        className="text-nowrap text-right font-[GhostKidAOEPro] text-[20px] text-fill-transparent"
        style={{
          background: 'linear-gradient(180deg, #FF9C41 41.7%, #FF8413 59.93%)',
          backgroundClip: 'text',
          WebkitBackgroundClip: 'text'
        }}
      >
        {task.progress}%
      </span>
    ) : isFinished ? (
      <object className="h-8 w-10 object-contain" type="image/svg+xml" data={SvgContent.tasks.checkmark} />
    ) : isExpired ? (
      <object className="h-8 w-10 object-contain" type="image/svg+xml" data={SvgContent.tasks.crossRed} />
    ) : undefined

  return (
    <div
      onClick={handleOnPlayClick}
      className="relative flex h-[128px] w-full cursor-pointer flex-row overflow-hidden rounded-2xl bg-btn-primary-outline-bg-gradient px-2 pb-2 shadow-card"
    >
      <CardBg isOdd={isOdd} type={task.learningAssignment.type} />

      <div className="z-40 flex h-full w-full flex-col items-start justify-center pl-32">
        <span className="text-[20px] font-bold text-[#2C0077]">
          {t(`learningAssignmentType.${task.learningAssignment.type.toLocaleLowerCase()}`)}
        </span>
        <span className="font-semibold text-[#2C0077]">
          {task.name || t(`learningAssignmentType.${task.learningAssignment.type.toLocaleLowerCase()}`).toUpperCase()}
        </span>
        <span className={`text-[12px] ${endDateIsLessThan1Day || isExpired ? 'text-[#FF004D]' : 'text-[#562D7F]'}`}>
          {endDate && isExpired
            ? `${t('ended')} ${dayjs(endDate).fromNow()}`
            : isFinished
              ? ''
              : endDate && isInProgress
                ? `${t('end')} ${dayjs(endDate).fromNow()}`
                : ''}
        </span>

        {isInProgress && (
          <LearningExecutionProgressBar
            className="mt-1"
            progressStyle={{
              background: endDateIsLessThan1Day
                ? 'linear-gradient(180deg, #FF8C7C 0%, #FF004D 100%)'
                : 'linear-gradient(180deg, #FF9A51 0%, #FC4C00 100%)'
            }}
            progress={isFinished ? task.successMeasure : task?.progress}
          />
        )}
      </div>

      <div className="flex h-full w-10 flex-col justify-between pt-4">
        <div className="flex-1" />
        <div className="h-10 w-10 overflow-hidden rounded-full">
          <LanguageFlag className="h-10 w-10" />
        </div>
      </div>

      <div className="absolute right-2 top-4">{ResultIcon}</div>
    </div>
  )
}

const CardBg = ({ isOdd, type }: { isOdd: boolean; type: LearningAssignmentType }) => {
  const colorOddSrc =
    type === LearningAssignmentType.TEST
      ? ImageContent.tasks.testOdd
      : type === LearningAssignmentType.HOMEWORK
        ? ImageContent.tasks.homeworkOdd
        : ImageContent.tasks.selfStudyOdd

  const colorEvenSrc =
    type === LearningAssignmentType.TEST
      ? ImageContent.tasks.testEven
      : type === LearningAssignmentType.HOMEWORK
        ? ImageContent.tasks.homeworkEven
        : ImageContent.tasks.selfStudyEven

  return <img className="absolute left-0 top-0 h-32 w-32 object-cover" src={isOdd ? colorOddSrc : colorEvenSrc} />
}
