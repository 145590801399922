import { Suspense } from 'react'

// TODO add router animation later
export const SettingsLayout = ({ children }) => {
  return (
    <Suspense>
      <div className="flex h-full w-full max-w-[1312px] flex-col items-center">{children}</div>
    </Suspense>
  )
}
