import { useEffect, useRef, useState } from 'react'
import * as SDK from 'microsoft-cognitiveservices-speech-sdk'

import { useTimeout } from '@foxino/components-common'

import Constants from '@app/constants'
import { TranslationPair } from '@app/data'
import { TranslationType } from '@app/modules/common/model/enums'
import { languageToCodeMap } from '@app/modules/common/model/languageToCode'
import { logError } from '@app/utils/logsUtils'
import { getRandomInt } from '@app/utils/numbersUtils'

type TtsGenderType = 'male' | 'female'
type TtsSupportedLanguage = 'cs-CZ' | 'en-US'
// type SupportedLanguage = 'cs-CZ' | 'en-US' | 'de-DE' | 'fr-FR' | 'it-IT' | 'es-ES'

const allVoices: Record<TtsSupportedLanguage, Record<string, string>> = {
  'cs-CZ': {
    antonin: 'cs-CZ-AntoninNeural',
    vlasta: 'cs-CZ-VlastaNeural'
  },
  'en-US': {
    jenny: 'en-US-JennyNeural',
    jennyMultilingual: 'en-US-JennyMultilingualNeural3',
    guy: 'en-US-GuyNeural',
    aria: 'en-US-AriaNeural',
    davis: 'en-US-DavisNeural',
    amber: 'en-US-AmberNeural',
    ana: 'en-US-AnaNeural',
    ashley: 'en-US-AshleyNeural',
    brandon: 'en-US-BrandonNeural',
    christopher: 'en-US-ChristopherNeural',
    cora: 'en-US-CoraNeural',
    elizabeth: 'en-US-ElizabethNeural',
    eric: 'en-US-EricNeural',
    jacob: 'en-US-JacobNeural',
    jane: 'en-US-JaneNeural',
    jason: 'en-US-JasonNeural',
    michelle: 'en-US-MichelleNeural',
    monica: 'en-US-MonicaNeural',
    nancy: 'en-US-NancyNeural',
    roger: 'en-US-RogerNeural',
    sara: 'en-US-SaraNeural',
    steffan: 'en-US-SteffanNeural',
    tony: 'en-US-TonyNeural',
    ai1: 'en-US-AIGenerate1Neural1',
    ai2: 'en-US-AIGenerate2Neural1'
  }
  // 'de-DE': {
  //   katja: 'de-DE-KatjaNeural',
  //   conrad: 'de-DE-ConradNeural',
  //   amala: 'de-DE-AmalaNeural',
  //   bernd: 'de-DE-BerndNeural',
  //   christoph: 'de-DE-ChristophNeural',
  //   elke: 'de-DE-ElkeNeural',
  //   gisela: 'de-DE-GiselaNeural',
  //   kasper: 'de-DE-KasperNeural',
  //   killian: 'de-DE-KillianNeural',
  //   klarissa: 'de-DE-KlarissaNeural',
  //   klaus: 'de-DE-KlausNeural',
  //   louisa: 'de-DE-LouisaNeural',
  //   maja: 'de-DE-MajaNeural',
  //   ralf: 'de-DE-RalfNeural',
  //   tanja: 'de-DE-TanjaNeural'
  // },
  // 'es-ES': {
  //   elvira: 'es-ES-ElviraNeural',
  //   alvaro: 'es-ES-AlvaroNeural',
  //   abril: 'es-ES-AbrilNeural',
  //   arnau: 'es-ES-ArnauNeural',
  //   dario: 'es-ES-DarioNeural',
  //   elias: 'es-ES-EliasNeural',
  //   estrella: 'es-ES-ElviraNeural',
  //   irene: 'es-ES-IreneNeural',
  //   laia: 'es-ES-LaiaNeural',
  //   nil: 'es-ES-NilNeural',
  //   saul: 'es-ES-SaulNeural',
  //   teo: 'es-ES-TeoNeural',
  //   triana: 'es-ES-TrianaNeural',
  //   vera: 'es-ES-VeraNeural'
  // },
  // 'fr-FR': {
  //   denise: 'fr-FR-DeniseNeural',
  //   henri: 'fr-FR-HenriNeural',
  //   alain: 'fr-FR-AlainNeural',
  //   brigitte: 'fr-FR-BrigitteNeural',
  //   celeste: 'fr-FR-CelesteNeural',
  //   claude: 'fr-FR-ClaudeNeural',
  //   coralie: 'fr-FR-CoralieNeural',
  //   eloise: 'fr-FR-EloiseNeural',
  //   jacqueline: 'fr-FR-JacquelineNeural',
  //   jerome: 'fr-FR-JeromeNeural',
  //   josephine: 'fr-FR-JosephineNeural',
  //   yves: 'fr-FR-YvesNeural',
  //   yvette: 'fr-FR-YvetteNeural'
  // },
  // 'it-IT': {
  //   elsa: 'it-IT-ElsaNeural',
  //   isabella: 'it-IT-IsabellaNeural',
  //   diego: 'it-IT-DiegoNeural',
  //   benigno: 'it-IT-BenignoNeural',
  //   calimero: 'it-IT-CalimeroNeural',
  //   cataldo: 'it-IT-CataldoNeural',
  //   fabiola: 'it-IT-FabiolaNeural',
  //   fiamma: 'it-IT-FiammaNeural',
  //   gianni: 'it-IT-GianniNeural',
  //   imelda: 'it-IT-ImeldaNeural',
  //   irma: 'it-IT-IrmaNeural',
  //   lisandro: 'it-IT-LisandroNeural',
  //   palmira: 'it-IT-PalmiraNeural',
  //   pierina: 'it-IT-PierinaNeural',
  //   rinaldo: 'it-IT-RinaldoNeural'
  // }
}

const voices: Record<TtsSupportedLanguage, Record<'male' | 'female', string | undefined>> = {
  'cs-CZ': {
    male: allVoices['cs-CZ'].antonin,
    female: allVoices['cs-CZ'].vlasta
  },
  'en-US': {
    male: allVoices['en-US'].steffan,
    female: allVoices['en-US'].elizabeth
  }
  // 'de-DE': {
  //   male: allVoices['de-DE'].conrad,
  //   female: allVoices['de-DE'].katja
  // },
  // 'es-ES': {
  //   male: allVoices['es-ES'].alvaro,
  //   female: allVoices['es-ES'].elvira
  // },
  // 'fr-FR': {
  //   male: allVoices['fr-FR'].jerome,
  //   female: allVoices['fr-FR'].brigitte
  // },
  // 'it-IT': {
  //   male: allVoices['it-IT'].diego,
  //   female: allVoices['it-IT'].elsa
  // }
}

// initialize sdk
SDK.Recognizer.enableTelemetry(false)

export type UseTtsReturnType = {
  playing: boolean
  speak: (transType: TranslationType, newTranslationPair?: TranslationPair) => void
  cancel: () => void
}

/**
 * Text to speech synthesis using MS Azure Speech service
 */
export const useTts = (
  translationPair?: TranslationPair,
  gender?: TtsGenderType,
  disposeAfterPlay = true
): UseTtsReturnType => {
  const [playing, setPlaying] = useState<boolean>(false)
  const sourceSynthesizerRef = useRef<SDK.SpeechSynthesizer>()
  const targetSynthesizerRef = useRef<SDK.SpeechSynthesizer>()
  const [startTimeout] = useTimeout()

  const shouldUseMaleVoice = Boolean(getRandomInt(2))
  const genderToUse: TtsGenderType = gender || shouldUseMaleVoice ? 'male' : 'female'

  // https://github.com/microsoft/cognitive-services-speech-sdk-js/issues/565
  // Uncaught DOMException: Failed to set the 'duration' property on 'MediaSource'
  // To fix this issue the AudioConfig initialization needs to be inside the hook
  useEffect(() => {
    sourceSynthesizerRef.current = undefined
    targetSynthesizerRef.current = undefined

    return () => {
      try {
        sourceSynthesizerRef.current?.close()
        targetSynthesizerRef.current?.close()
      } catch (error) {
        console.info('Sound synthesizer already disposed')
      }
    }
  }, [])

  useEffect(() => {
    sourceSynthesizerRef.current = undefined
    targetSynthesizerRef.current = undefined
  }, [translationPair])

  const initSourceSynthesizer = (language: TtsSupportedLanguage) => {
    if (sourceSynthesizerRef.current) return

    const audioVoice = genderToUse === 'male' ? voices[language].male : voices[language].female

    const speechConfig = SDK.SpeechConfig.fromSubscription(Constants.SPEECH_KEY, Constants.SPEECH_REGION)
    speechConfig.speechSynthesisVoiceName = audioVoice || 'en-US-JennyNeural'

    const audioConfigFromSpeaker = SDK.AudioConfig.fromDefaultSpeakerOutput()
    sourceSynthesizerRef.current = new SDK.SpeechSynthesizer(speechConfig, audioConfigFromSpeaker)
  }

  const initTargetSynthesizer = (language: TtsSupportedLanguage) => {
    const audioVoice = genderToUse === 'male' ? voices[language].male : voices[language].female

    const speechConfig = SDK.SpeechConfig.fromSubscription(Constants.SPEECH_KEY, Constants.SPEECH_REGION)
    speechConfig.speechSynthesisVoiceName = audioVoice || 'en-US-JennyNeural'

    const audioConfigFromSpeaker = SDK.AudioConfig.fromDefaultSpeakerOutput()
    targetSynthesizerRef.current = new SDK.SpeechSynthesizer(speechConfig, audioConfigFromSpeaker)
  }

  const playSoundViaSynthesizer = (text: string, synthesizer?: SDK.SpeechSynthesizer) => {
    if (!synthesizer) return

    setPlaying(true)

    try {
      synthesizer?.speakTextAsync(
        text,
        (result) => {
          if (result.audioDuration) {
            startTimeout(() => {
              setPlaying(false)
            }, result.audioDuration / 10000)
          }

          if (result.errorDetails) {
            logError(result.errorDetails, 'useTts', 'speak', undefined, false)
          }
          if (disposeAfterPlay) {
            synthesizer?.close()
          }

          synthesizer = undefined
        },
        (error) => {
          synthesizer?.close()
          synthesizer = undefined
          logError(error, 'useTts', 'speak')
        }
      )
    } catch (error) {
      logError(error, 'useTts', 'playSoundViaSynthesizer')
    }
  }

  const speak = (transType: TranslationType, newTranslationPair?: TranslationPair) => {
    const pairToUse = newTranslationPair || translationPair
    if (!pairToUse) {
      return
    }

    const lf = transType === TranslationType.SOURCE ? pairToUse.sourceLearningFeature : pairToUse.targetLearningFeature
    const language = languageToCodeMap[lf.language] as TtsSupportedLanguage

    if (transType === TranslationType.SOURCE) {
      if (!sourceSynthesizerRef.current || newTranslationPair) {
        initSourceSynthesizer(language)
      }

      playSoundViaSynthesizer(lf.text, sourceSynthesizerRef.current)
    } else {
      if (!targetSynthesizerRef.current || newTranslationPair) {
        initTargetSynthesizer(language)
      }

      playSoundViaSynthesizer(lf.text, targetSynthesizerRef.current)
    }
  }

  const cancel = () => {
    try {
      sourceSynthesizerRef.current?.close()
      targetSynthesizerRef.current?.close()
      sourceSynthesizerRef.current = undefined
      targetSynthesizerRef.current = undefined
      setPlaying(false)
    } catch (error) {
      // Sound synthesizer already disposed'
    }
  }

  return {
    playing,
    speak,
    cancel
  }
}
