import CommonExitDialogBg from './img/common/exit_dialog_bg.png'
import CommonIconFoxino from './img/common/icon_foxino.png'
import CommonInsufficientRole from './img/common/insufficient_roles.png'
import CommonPurpleFoxino from './img/common/purple_foxino.png'
import CommonPurpleFoxinoClosedEyes from './img/common/purple_foxino_closed_eyes.png'
import LeaderboardBronzeColumn from './img/leaderboard/bronze_column.png'
import LeaderboardGoldColumn from './img/leaderboard/gold_column.png'
import LeaderboardSilverColumn from './img/leaderboard/silver_column.png'
import LearningPathSliceBg from './img/learningPath/bg.png'
import LearningPathCloud1 from './img/learningPath/cloud_1.png'
import LearningPathCloud2 from './img/learningPath/cloud_2.png'
import LearningPathLandscape from './img/learningPath/landscape.png'
import LearningPathPlanet1 from './img/learningPath/planet_1.png'
import LearningPathPlanet2 from './img/learningPath/planet_2.png'
import LearningPathSkye from './img/learningPath/sky.png'
import LearningPathWater from './img/learningPath/water.png'
import LessonCloud from './img/lesson/lesson_cloud.png'
import LessonEndAvatar from './img/lesson/lesson_end_avatar.png'
import LessonLoadingBarBg from './img/lesson/loading_bar_bg.png'
import LessonLoadingFill from './img/lesson/loading_bar_fill.png'
import LessonMicRecording from './img/lesson/mic_recording.png'
import LessonSoundSpeaker from './img/lesson/sound_speaker.png'
import LessonTestTimer from './img/lesson/timer.png'
import LessonTurtleBtn from './img/lesson/turtle_btn.png'
import QuizGameArrowDown from './img/quizGame/arrow_drop_down_red.png'
import QuizGameArrowUp from './img/quizGame/arrow_drop_up_green.png'
import QuizGameArrowSamePlace from './img/quizGame/arrow_same_place.png'
import QuizGameBadge from './img/quizGame/badge.png'
import QuizGameBottomBarBg from './img/quizGame/bottom_bar_bg.png'
import QuizGameCheckmark from './img/quizGame/checkmark.png'
import QuizGameCheckmarkLines from './img/quizGame/checkmark_lines.png'
import QuizGameConfetti from './img/quizGame/confetti.png'
import QuizGameEclipse from './img/quizGame/eclipse.svg'
import QuizGameEclipseInner from './img/quizGame/eclipse_inner.svg'
import QuizGameEmojiPeople from './img/quizGame/emoji_people.svg'
import QuizGameExitBanner from './img/quizGame/exit_banner.png'
import QuizGameFirstWinner from './img/quizGame/first_winner.png'
import QuizGameLogo from './img/quizGame/logo.png'
import QuizGameModalBg from './img/quizGame/modal_bg.png'
import QuizGameQuestionFrameBg from './img/quizGame/question_frame_bg.png'
import QuizGameRedCross from './img/quizGame/red_cross.png'
import QuizGameSecondWinner from './img/quizGame/second_winner.png'
import QuizGameThirdWinner from './img/quizGame/third_winner.png'
import QuizGameTitleBg from './img/quizGame/title_bg.png'
import QuizGameTrophy from './img/quizGame/trophy.png'
import TasksAllDoneForToday from './img/tasks/all_done_for_today.png'
import TasksHomeworkBg_1 from './img/tasks/homework_big_bg_1.jpg'
import TasksHomeworkBg_2 from './img/tasks/homework_big_bg_2.jpg'
import TasksHomeworkEven from './img/tasks/homework_even.png'
import TasksHomeworkIcon from './img/tasks/homework_icon.png'
import TasksHomeworkOdd from './img/tasks/homework_odd.png'
import TasksHomeworkProgressBarBg from './img/tasks/homework_progress_bar_bg.png'
import TasksHomeworkProgressBarFg from './img/tasks/homework_progress_bar_fg.png'
import TasksHomeworkSmallBg_1 from './img/tasks/homework_small_bg_1.png'
import TasksSelfStudyBg_1 from './img/tasks/selfstudy_big_bg_1.jpg'
import TasksSelfStudyEven from './img/tasks/selfStudy_even.png'
import TasksSelfStudyIcon from './img/tasks/selfstudy_icon.png'
import TasksSelfStudyOdd from './img/tasks/selfStudy_odd.png'
import TasksSelfStudyProgressBarBg from './img/tasks/selfstudy_progress_bar_bg.png'
import TasksSelfStudyProgressBarFg from './img/tasks/selfstudy_progress_bar_fg.png'
import TasksSelfStudySmallBg_1 from './img/tasks/selfstudy_small_bg_1.png'
import TasksContentHomework from './img/tasks/task_homework.png'
import TasksContentSelfStudy from './img/tasks/task_selfstudy.png'
import TasksContentTest from './img/tasks/task_test.png'
import TasksTestBg_1 from './img/tasks/test_big_bg_1.jpg'
import TasksTestEven from './img/tasks/test_even.png'
import TasksTestIcon from './img/tasks/test_icon.png'
import TasksTestOdd from './img/tasks/test_odd.png'
import TasksTestProgressBarBg from './img/tasks/test_progress_bar_bg.png'
import TasksTestProgressBarFg from './img/tasks/test_progress_bar_fg.png'
import TasksTestSmallBg_1 from './img/tasks/test_small_bg_1.png'

export const ImageContent = {
  common: {
    purpleFoxino: CommonPurpleFoxino,
    purpleFoxinoClosedEyes: CommonPurpleFoxinoClosedEyes,
    insufficientRole: CommonInsufficientRole,
    exitDialogBg: CommonExitDialogBg,
    iconFoxino: CommonIconFoxino
  },

  tasks: {
    allDoneForToday: TasksAllDoneForToday,
    homework: TasksContentHomework,
    homeworkBg1: TasksHomeworkBg_1,
    homeworkBg2: TasksHomeworkBg_2,
    homeworkEven: TasksHomeworkEven,
    homeworkIcon: TasksHomeworkIcon,
    homeworkOdd: TasksHomeworkOdd,
    homeworkProgressBarBg: TasksHomeworkProgressBarBg,
    homeworkProgressBarFg: TasksHomeworkProgressBarFg,
    homeworkSmallBg1: TasksHomeworkSmallBg_1,
    selfStudy: TasksContentSelfStudy,
    selfStudyBg1: TasksSelfStudyBg_1,
    // selfStudyBg2: TasksSelfStudyBg_2, // TODO design missing
    selfStudyEven: TasksSelfStudyEven,
    selfStudyIcon: TasksSelfStudyIcon,
    selfStudyOdd: TasksSelfStudyOdd,
    selfStudyProgressBarBg: TasksSelfStudyProgressBarBg,
    selfStudyProgressBarFg: TasksSelfStudyProgressBarFg,
    selfStudySmallBg1: TasksSelfStudySmallBg_1,
    test: TasksContentTest,
    testBg1: TasksTestBg_1,
    // testBg2: TasksTestBg_2, // TODO design missing
    testEven: TasksTestEven,
    testIcon: TasksTestIcon,
    testOdd: TasksTestOdd,
    testProgressBarBg: TasksTestProgressBarBg,
    testProgressBarFg: TasksTestProgressBarFg,
    testSmallBg1: TasksTestSmallBg_1
  },

  leaderboard: {
    gold: LeaderboardGoldColumn,
    silver: LeaderboardSilverColumn,
    bronze: LeaderboardBronzeColumn
  },

  lesson: {
    endAvatar: LessonEndAvatar,
    lessonCloud: LessonCloud,
    loadingBarBg: LessonLoadingBarBg,
    loadingBarFill: LessonLoadingFill,
    micRecording: LessonMicRecording,
    soundSpeaker: LessonSoundSpeaker,
    timer: LessonTestTimer,
    turtleBtn: LessonTurtleBtn
  },

  quizGame: {
    arrowDown: QuizGameArrowDown,
    arrowSamePlace: QuizGameArrowSamePlace,
    arrowUp: QuizGameArrowUp,
    badge: QuizGameBadge,
    bottomBarBg: QuizGameBottomBarBg,
    checkmark: QuizGameCheckmark,
    checkmarkLines: QuizGameCheckmarkLines,
    confetti: QuizGameConfetti,
    eclipse: QuizGameEclipse,
    eclipseInner: QuizGameEclipseInner,
    emojiPeople: QuizGameEmojiPeople,
    exitBanner: QuizGameExitBanner,
    firstWinner: QuizGameFirstWinner,
    logo: QuizGameLogo,
    modalBg: QuizGameModalBg,
    questionFrameBg: QuizGameQuestionFrameBg,
    redCross: QuizGameRedCross,
    secondWinner: QuizGameSecondWinner,
    thirdWinner: QuizGameThirdWinner,
    titleBg: QuizGameTitleBg,
    trophy: QuizGameTrophy
  },

  learningPath: {
    cloud1: LearningPathCloud1,
    cloud2: LearningPathCloud2,
    landscape: LearningPathLandscape,
    planet1: LearningPathPlanet1,
    planet2: LearningPathPlanet2,
    sky: LearningPathSkye,
    sliceBg: LearningPathSliceBg,
    water: LearningPathWater
  }
}
