import { useNavigate } from 'react-router-dom'
import shuffle from 'lodash/shuffle'
import { RefreshCw01 } from '@untitled-ui/icons-react'

import { SvgContent } from '@app/assets/SvgContent'
import { Button, Heading, Text } from '@app/components'
import { Pages } from '@app/config/router/Pages'
import { useTranslation } from '@app/locales'

export const NoClassSection = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('error')

  const randomImgData = shuffle([
    SvgContent.error.img1,
    SvgContent.error.img2,
    SvgContent.error.img3,
    SvgContent.error.img4,
    SvgContent.error.img5
  ])[0]

  const handleJoinClassClick = () => {
    navigate(Pages.SETTINGS_ADD_CLASSROOM)
  }

  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-4 pb-8">
      <object className="h-[350px] w-[90%] max-w-[480px] md:h-[480px]" type="image/svg+xml" data={randomImgData} />

      <Heading size="l" className="text-neutral50">
        {t('noClass')}
      </Heading>

      <Text className="max-w-screen-md px-8 text-center text-neutral50">{t('noClassDescription')}</Text>

      <Button
        className="mt-8 w-[280px]"
        btnClassName="w-full flex flex-row gap-3"
        mode="primary"
        onClick={handleJoinClassClick}
      >
        <RefreshCw01 />
        <Text size="l" weight="bold">
          {t('addNewClassroom')}
        </Text>
      </Button>
    </div>
  )
}
