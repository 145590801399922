import { createBreakpoint } from 'react-use'

// see also tailwind.config.js
const breakpoints = {
  xs: 0, // default minWidth
  sm: 391, // tablet - minWidth
  md: 769, // tablet landscape - minWidth
  lg: 1025, // tablet - maxWidth; desktop - minWidth
  xl: 1441 // desktop
}

export type Breakpoints = keyof typeof breakpoints

type ReturnType = () => Breakpoints
export const useBreakpoints: ReturnType = createBreakpoint(breakpoints) as ReturnType
