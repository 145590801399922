import { useEffect, useMemo, useRef, useState } from 'react'
import * as THREE from 'three'

import { ImageContent } from '@app/assets/ImageContent'

import {
  MAIN_PATH_AMPLITUDE,
  MAIN_PATH_FREQUENCY,
  NUMBER_OF_SEGMENTS,
  PATH_END_OFFSET,
  SinusoidalCurve3,
  SPHERE_RADIUS
} from '../constants'

const images = [
  ImageContent.learningPath.sliceBg,
  ImageContent.learningPath.sliceBg,
  ImageContent.learningPath.sliceBg,
  ImageContent.learningPath.sliceBg
]

export const SphereObject = () => {
  const [combinedTexture, setCombinedTexture] = useState<THREE.Texture>()

  const sphereRef = useRef<THREE.Mesh>(null)

  useEffect(() => {
    const loadAndCombineImages = async () => {
      const loader = new THREE.TextureLoader()
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')

      try {
        const textures = await Promise.all(images.map((path) => loader.loadAsync(path)))

        // all images should have the same dimensions
        const width = textures[0].image.width / window.devicePixelRatio
        const height = textures[0].image.height / window.devicePixelRatio

        // set canvas width to match one image's width and height to fit all four images vertically
        canvas.width = width
        canvas.height = height * 4

        if (context) {
          // draw the images in a 1x4 grid (stacked vertically)
          textures.forEach((texture, index) => {
            context.drawImage(texture.image, 0, height * index, width, height)
          })

          // draw the sine wave
          // * Canvas: 2D pixel-based
          // * Sphere: 3D space with spherical coordinates.
          // * Amplitude: Pixel values on the canvas vs. unit values on the sphere.
          // * Frequency: Number of cycles per pixel on the canvas vs. cycles around the sphere.
          // * So we need to modify the canvas sine wave parameters (amplitude and frequency) so that it visually matches the sine wave pattern of the anchor positions on the sphere.
          // * Frequency Calculation: Frequency (f_canvas) = Number of cycles (C) * 2π radians / Canvas Height (H)
          // * Number of Cycles: The canvas sine wave should have the same number of cycles (MAIN_PATH_FREQUENCY) as the sphere’s sine wave.
          // * Canvas Height (H): After stacking four images vertically, the canvas height is image's height * 4.
          const frequency = (MAIN_PATH_FREQUENCY * 2 * Math.PI) / canvas.height
          // * 34 - Scaling Factor - determine how many pixels correspond to one unit on the sphere, defined purely visually
          const amplitude = MAIN_PATH_AMPLITUDE * (32 / window.devicePixelRatio)
          const xOffset = width / 2 // center horizontally

          // FIXME - too blurry, how to fix?
          context.beginPath()
          context.lineWidth = 120 / window.devicePixelRatio
          context.lineCap = 'round' // to make the end of the stripe "connected"
          context.strokeStyle = 'rgba(100, 84, 151)'
          for (let y = PATH_END_OFFSET; y <= canvas.height - PATH_END_OFFSET; y++) {
            const x = amplitude * Math.sin(frequency * y) + xOffset
            context.lineTo(x, y)
          }
          context.stroke()
        }

        const combinedTexture = new THREE.CanvasTexture(canvas)

        setCombinedTexture(combinedTexture)
      } catch (error) {
        console.error('Error loading or processing images:', error)
      }
    }

    loadAndCombineImages()
  }, [])

  // create the tube geometry connecting anchor positions with sinusoidal curve
  const tubeGeometry = useMemo(() => {
    const sinusoidalCurve = new SinusoidalCurve3(MAIN_PATH_AMPLITUDE, MAIN_PATH_FREQUENCY, SPHERE_RADIUS)
    return new THREE.TubeGeometry(sinusoidalCurve, 1024, 0.1, 128)
  }, [])

  // material for the tube
  const tubeMaterial = useMemo(() => new THREE.MeshStandardMaterial({ vertexColors: true }), [])

  // TODO (e.g., 0.03 for 3%) apply data from API
  const tubeProgressPercentage = 0.03

  // generate vertex colors based on the percentage
  useEffect(() => {
    const positions = tubeGeometry.attributes.position
    const vertexCount = positions.count
    const colors = new Float32Array(vertexCount * 3)

    const tubularSegments = tubeGeometry.parameters.tubularSegments
    const radialSegments = tubeGeometry.parameters.radialSegments

    for (let i = 0; i <= tubularSegments; i++) {
      const t = i / tubularSegments
      const color = new THREE.Color()

      if (t >= 1 - tubeProgressPercentage) {
        color.set('orange')
      } else {
        color.set('white')
      }

      for (let j = 0; j <= radialSegments; j++) {
        const index = (i * (radialSegments + 1) + j) * 3
        colors[index] = color.r
        colors[index + 1] = color.g
        colors[index + 2] = color.b
      }
    }

    tubeGeometry.setAttribute('color', new THREE.BufferAttribute(colors, 3))
  }, [tubeGeometry, tubeProgressPercentage])

  if (!combinedTexture) {
    return null
  }

  // quality parameters
  combinedTexture.minFilter = THREE.LinearFilter
  combinedTexture.magFilter = THREE.LinearFilter
  combinedTexture.generateMipmaps = true
  combinedTexture.anisotropy = 16

  // wrapping parameters
  combinedTexture.wrapS = THREE.RepeatWrapping
  combinedTexture.wrapT = THREE.RepeatWrapping
  combinedTexture.rotation = Math.PI / 2
  combinedTexture.repeat.set(1, 1)
  combinedTexture.center.set(0.5, 0.5)

  return (
    <group>
      <mesh
        ref={sphereRef}
        rotation={[Math.PI, Math.PI, Math.PI / 2]}
        scale={[1, 1.5, 1]} // sphere is now an ellipsoid due to the y-axis scaling
        receiveShadow
      >
        <sphereGeometry args={[SPHERE_RADIUS, NUMBER_OF_SEGMENTS, NUMBER_OF_SEGMENTS]} />
        <meshStandardMaterial map={combinedTexture} />
      </mesh>

      {tubeGeometry && <mesh geometry={tubeGeometry} material={tubeMaterial} />}
    </group>
  )
}
