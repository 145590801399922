import ReactDOM from 'react-dom/client'

import App from './App.tsx'

import 'swiper/css'
import 'react-toastify/dist/ReactToastify.min.css'
import './style/fonts.css'
import './style/tailwind.css'

import 'swiper/css/pagination'
import 'swiper/css/virtual'

async function enableMocking() {
  if (import.meta.env.MODE === 'development') {
    const { setupMockWorker } = await import('./mock/worker.mock.ts')
    await setupMockWorker().start({ onUnhandledRequest: 'bypass' })
  }

  return Promise.resolve()
}

enableMocking().then(() => {
  ReactDOM.createRoot(document.getElementById('root')!).render(<App />)
})
