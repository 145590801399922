import { Dialog, DialogPanel } from '@headlessui/react'
import { AnimatePresence, motion } from 'framer-motion'

import { ImageContent } from '@app/assets/ImageContent'
import { Button } from '@app/components'
import { useTranslation } from '@app/locales'
import { logout } from '@app/modules/auth/actions/authActions'

type ExitLessonDialogProps = {
  isDialogVisible: boolean
  onDismiss: () => void
}

export const ExitAppDialog = ({ isDialogVisible, onDismiss }: ExitLessonDialogProps) => {
  const { t } = useTranslation('settings')

  const handleOnExitAppClick = () => {
    logout()
  }

  return (
    <AnimatePresence>
      {isDialogVisible && (
        <Dialog static open={isDialogVisible} onClose={onDismiss} className="relative z-50">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/50 backdrop-blur"
          />
          <div className="fixed inset-0 flex w-screen items-center justify-center px-2">
            <DialogPanel
              as={motion.div}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              className="relative w-full max-w-[374px] overflow-hidden rounded-3xl"
            >
              <div className="relative flex h-[520px] w-full flex-col">
                <img src={ImageContent.common.exitDialogBg} className="w-full" />

                <svg
                  className="absolute bottom-0 z-10"
                  width="374"
                  height="307"
                  viewBox="0 0 374 307"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-8.51147 290.525L-0.196114 10.1134C-0.0823097 6.27567 2.75889 3.04352 6.56351 2.52801C86.1069 -8.24992 125.604 23.3738 214 33.9571C323.735 47.0952 341.766 28.791 362.389 12.0157C367.259 8.054 374 11.63 374 17.908V291C374 299.836 366.837 307 358 307H7.4815C-1.54087 307 -8.7789 299.544 -8.51147 290.525Z"
                    fill="url(#paint0_linear_763_31472)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_763_31472"
                      x1="182.5"
                      y1="0.319946"
                      x2="182.5"
                      y2="307"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#C3BDFF" />
                      <stop offset="1" stopColor="#F5F4FF" />
                    </linearGradient>
                  </defs>
                </svg>

                <div className="absolute bottom-0 z-20 flex w-full flex-col items-center gap-2 p-4">
                  <div className="px-4 text-center text-[26px] font-extrabold text-neutral1000">
                    {t('logout.confirm')}
                  </div>
                  <div className="mb-2 text-center font-semibold text-neutral800">{t('logout.description')}</div>

                  <Button
                    className="w-full"
                    btnClassName="bg-incorrect-gradient hover:bg-incorrect-hover-gradient shadow-incorrect text-neutral50"
                    mode="outline"
                    onClick={handleOnExitAppClick}
                  >
                    {t('logout.title')}
                  </Button>

                  <Button className="w-full" mode="outline" onClick={onDismiss}>
                    {t('close')}
                  </Button>
                </div>
              </div>
            </DialogPanel>
          </div>
        </Dialog>
      )}
    </AnimatePresence>
  )
}
