import * as Sentry from '@sentry/react'
import { createClient } from 'graphql-ws'
import { v4 as uuidv4 } from 'uuid'
import {
  ApolloClient,
  ApolloLink,
  fromPromise,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  ServerError,
  ServerParseError,
  split
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'

import Constants from '@app/constants'
import { getAccessToken, logout } from '@app/modules/auth/actions/authActions'
import { tokenStore } from '@app/modules/auth/store/tokenStore'

import { HTTPStatusCodeEnum } from '../model/enums'
import { apolloStore } from '../store/apolloStore'

export const initApolloClient = (): void => {
  const apolloClient = getApolloClient()
  apolloStore.setApolloClient(apolloClient)
}

type ReturnType = ApolloClient<NormalizedCacheObject>

const getApolloClient = (): ReturnType => {
  const cache = new InMemoryCache({
    typePolicies: {
      TrainingRoom: {
        merge: true
      },
      Query: {
        fields: {
          getOrderedItems: {
            keyArgs: ['filter', 'sorting'],
            merge(existing = [], incoming) {
              return [...existing, ...incoming]
            }
          },
          getOrders: {
            keyArgs: ['filter', 'sorting'],
            merge(existing = [], incoming) {
              return [...existing, ...incoming]
            }
          }
        }
      },
      OrderItem: {
        keyFields: ['commonApiId']
      }
    }
  })

  const errorLink = onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        switch (err.extensions?.code) {
          case 'UNAUTHENTICATED':
            return fromPromise(
              getAccessToken(3600).catch(() => {
                logout()
                return
              })
            )
              .filter((value) => Boolean(value))
              .flatMap(() => {
                const newAccessToken = tokenStore.useStore.getState().accessToken
                const oldHeaders = operation.getContext().headers

                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    authorization: `Bearer ${newAccessToken}`
                  }
                })
                return forward(operation)
              })
        }
      }
    }
  })

  const operationNameCounts: Record<string, number> = {}

  const retryLink = new RetryLink({
    attempts: {
      max: Constants.APOLLO_MAX_RETRIES,
      retryIf: async (error, operation) => {
        // Increment the count for the current operationName
        const operationName = operation.operationName

        if (operationNameCounts[operationName] >= Constants.APOLLO_MAX_RETRIES - 1) {
          logout()
        }

        try {
          if (error && hasStatusCode(error) && error.statusCode === HTTPStatusCodeEnum.UNAUTHORIZED) {
            if (operationName) {
              if (!operationNameCounts[operationName]) {
                operationNameCounts[operationName] = 0
              }
              operationNameCounts[operationName]++
            }

            await getAccessToken(3600)

            return true
          }

          return false
        } catch (e) {
          return false
        }
      }
    },
    delay: { initial: 300, max: 1000 }
  })

  const customFetch: WindowOrWorkerGlobalScope['fetch'] = async (uri, options) => {
    if (!options) options = {}
    if (!options?.headers) {
      options.headers = {}
    }

    const authToken = tokenStore.useStore.getState().accessToken || ''

    const traceId = uuidv4()

    options.headers = {
      ...options.headers,
      ...(authToken && { Authorization: `Bearer ${authToken}` }),
      'trace-id': `${traceId}`
    }

    Sentry.setContext('trace-id', { traceId })

    return new Promise((resolve, reject) => {
      const timer = setTimeout(() => {
        reject(new Error('Request timed out'))
      }, Constants.APOLLO_DEFAULT_TIMEOUT)
      fetch(uri, options).then(
        (response) => {
          clearTimeout(timer)
          resolve(response)
        },
        (err) => {
          clearTimeout(timer)
          reject(err)
        }
      )
    })
  }

  const httpLink = new HttpLink({
    uri: `${Constants.BASE_API_URL}/graphql`,
    fetch: customFetch
  })

  let activeSocket: any, timedOut: any
  const websocketLink = new GraphQLWsLink(
    createClient({
      url: `${Constants.BASE_API_URL}/subscriptions`,
      connectionParams: { authToken: tokenStore.useStore.getState().accessToken || '' },
      keepAlive: 10_000,
      on: {
        connected: (socket) => {
          console.info('websocket client MS connected!')
          activeSocket = socket
        },
        closed: () => {
          console.info('websocket client MS closed!')
        },
        error: () => {
          console.info('websocket client MS received an error!')
        },
        ping: (received) => {
          if (!received)
            // sent
            timedOut = setTimeout(() => {
              if (activeSocket.readyState === WebSocket.OPEN) {
                activeSocket.close(4408, 'Request Timeout')
              }
            }, 5_000) // wait 5 seconds for the pong and then close the connection
        },
        pong: (received) => {
          if (received) {
            clearTimeout(timedOut) // pong is received, clear connection close timeout
          }
        }
      }
    })
  )

  // The split function takes three parameters:
  //
  // * A function that's called for each operation to execute
  // * The Link to use for an operation if the function returns a "truthy" value
  // * The Link to use for an operation if the function returns a "falsy" value
  // queries and mutations will use HTTP as normal, subscriptions will use WebSocket
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    websocketLink,
    ApolloLink.from([retryLink, errorLink, httpLink])
  )

  return new ApolloClient({
    connectToDevTools: true,
    cache,
    link: splitLink,
    defaultOptions: {
      watchQuery: {
        nextFetchPolicy: 'cache-and-network'
      }
    }
  })
}

const hasStatusCode = (networkError: any): networkError is ServerError | ServerParseError => {
  return Boolean(networkError && networkError.statusCode)
}
