import { useRef } from 'react'

import { ImageContent } from '@app/assets/ImageContent'
import { VideoContent } from '@app/assets/VideoContent'
import { LearningAssignmentType, LearningExecutionState } from '@app/data'
import { useTranslation } from '@app/locales'
import { subjectToFlagRoundIcon } from '@app/utils/iconUtils'

import { LearningExecutionType } from '../model/types'

type Props = {
  task: LearningExecutionType
  onClick: (task: LearningExecutionType, type: string) => void
}

export const TaskCardBig = ({ task, onClick }: Props) => {
  const { t } = useTranslation('dashboard')
  const videoRef = useRef<HTMLVideoElement>(null)

  const isInProgress = task.state === LearningExecutionState.IN_PROGRESS

  const isTest = task.learningAssignment.type === LearningAssignmentType.TEST
  const isHomework = task.learningAssignment.type === LearningAssignmentType.HOMEWORK

  const videoSrc = isTest
    ? VideoContent.tasks.test
    : isHomework
      ? VideoContent.tasks.homework
      : VideoContent.tasks.selfStudy

  const contentSmallBgSrc = isTest
    ? ImageContent.tasks.testSmallBg1
    : isHomework
      ? ImageContent.tasks.homeworkSmallBg1
      : ImageContent.tasks.selfStudySmallBg1

  const contentBgSrc = isTest
    ? ImageContent.tasks.test
    : isHomework
      ? ImageContent.tasks.homework
      : ImageContent.tasks.selfStudy

  const progressBarBgSrc = isTest
    ? ImageContent.tasks.testProgressBarBg
    : isHomework
      ? ImageContent.tasks.homeworkProgressBarBg
      : ImageContent.tasks.selfStudyProgressBarBg

  const progressBarFgSrc = isTest
    ? ImageContent.tasks.testProgressBarFg
    : isHomework
      ? ImageContent.tasks.homeworkProgressBarFg
      : ImageContent.tasks.selfStudyProgressBarFg

  const handleOnPlayClick = () => {
    if (isInProgress) {
      onClick(task, 'even')
    }
  }

  const LanguageFlag = subjectToFlagRoundIcon[task.learningAssignment.subject]
  const progress = task.progress ? (task.progress < 5 ? 5 : task.progress) : 0
  const clipPath = `inset(0 calc(100% - ${progress}%) 0 0)`

  return (
    <div
      className="relative flex h-[364px] w-full cursor-pointer flex-col items-center justify-end overflow-hidden rounded-3xl shadow-card"
      onClick={handleOnPlayClick}
    >
      <video
        ref={videoRef}
        className="absolute left-0 top-0 z-0 w-full"
        poster={contentSmallBgSrc}
        playsInline={true}
        loop={true}
        autoPlay={true}
        muted={true}
      >
        <source src={videoSrc} type="video/webm"></source>
      </video>

      <div className="absolute bottom-0 h-[128px] w-full overflow-hidden">
        {isInProgress && <img className="z-10 h-full w-full" src={progressBarBgSrc} />}

        {isInProgress && (
          <div className="absolute bottom-0 z-20 h-full w-full overflow-hidden" style={{ clipPath }}>
            <img className="z-20 h-full w-full" src={progressBarFgSrc} />
          </div>
        )}

        <img className="absolute bottom-0 z-30 h-[calc(100%-8px)] w-full" src={contentBgSrc} />
      </div>

      <div className="relative z-40 flex h-[128px] w-full flex-row items-center justify-between p-4 pb-4 pr-2">
        <div className="flex h-full w-full flex-col justify-between pt-4">
          <div className="w-full text-[20px] font-bold text-[#2C0077]">
            {t(`learningAssignmentType.${task.learningAssignment.type.toLocaleLowerCase()}`)}
          </div>
          <div className="font-semibold text-[#2C0077]">
            {task.name || t(`learningAssignmentType.${task.learningAssignment.type.toLocaleLowerCase()}`).toUpperCase()}
          </div>
        </div>

        <div className="flex h-full w-10 flex-col justify-between pt-4">
          <div className="flex-1"></div>
          <div className="h-10 w-10 overflow-hidden rounded-full">
            <LanguageFlag className="h-10 w-10" />
          </div>
        </div>
      </div>
    </div>
  )
}
