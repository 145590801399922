import axios from 'axios'

export const addUnauthorizedHandlerToAxios = (logout: () => void, updateAuthentication: () => Promise<void>): void => {
  // Add a response interceptor
  axios.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    async (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (axios.isAxiosError(error) && error.code === '401') {
        try {
          await updateAuthentication()
        } catch {
          logout()
        }
      }
      return Promise.reject(error)
    }
  )
}

export const addAuthorizationTokenToAxios = (accessToken: string): void => {
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
}

export const removeAuthorizationTokenFromAxios = (): void => {
  axios.defaults.headers.common.Authorization = undefined
}
