import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { useFrame, useLoader } from '@react-three/fiber'
import * as THREE from 'three'
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js'
import * as SkeletonUtils from 'three/addons/utils/SkeletonUtils.js'

import { useTimeout } from '@foxino/components-common'

type ActionAnchorProps = {
  position: THREE.Vector3Tuple
  rotation: THREE.Euler
  onAnchorClick: () => void
}

// TODO pair with API from BE
const desiredTrackNames = [
  // 'Progress_bar_spehere.scale',
  // 'Progress_bar_spehere001.scale',
  // 'Progress_bar_spehere001.position',
  // 'Progress_bar_spehere001.quaternion',
  'Progress_Bar_1.scale',
  'Progress_Bar_1.quaternion',
  'Progress_Bar_2.scale',
  'Progress_Bar_2.quaternion',
  'Progress_Bar_3.scale',
  'Progress_Bar_3.quaternion',
  'Progress_Bar_4.scale',
  'Progress_Bar_4.quaternion'
  // 'Progress_Bar_5.scale',
  // 'Progress_Bar_5.quaternion',
  // 'Progress_Bar_6.scale',
  // 'Progress_Bar_6.quaternion',
  // 'Progress_Bar_7.scale',
  // 'Progress_Bar_7.quaternion',
  // 'Progress_Bar_8.scale',
  // 'Progress_Bar_8.quaternion',
  // 'Progress_Bar_9.scale',
  // 'Progress_Bar_9.quaternion',
  // 'Progress_Bar_10.scale',
  // 'Progress_Bar_10.quaternion'
]

export const ActionAnchorModel = memo(({ position, rotation, onAnchorClick }: ActionAnchorProps) => {
  const [isPlaying, setIsPlaying] = useState(false)

  const progressBarMixerRef = useRef<THREE.AnimationMixer | null>(null)
  const buttonBaseMixerRef = useRef<THREE.AnimationMixer | null>(null)
  const progressCylinderMixerRef = useRef<THREE.AnimationMixer | null>(null)

  // const actionRef = useRef<THREE.AnimationAction | null>(null)
  const clickedRef = useRef(false) // to track if click has been handled

  const [callTimer] = useTimeout(250)

  const progressBarGlb = useLoader(GLTFLoader, '/learningPath/3d/button_progress_bar.glb')
  const buttonBaseGlb = useLoader(GLTFLoader, '/learningPath/3d/button_base.glb')
  const progressCylinderGlb = useLoader(GLTFLoader, '/learningPath/3d/button_cylinder.glb')

  // clone the scene to prevent mutations
  const clonedProgressBarScene = useMemo(() => {
    return SkeletonUtils.clone(progressBarGlb.scene)
  }, [progressBarGlb.scene])
  const clonedButtonBaseScene = useMemo(() => {
    return SkeletonUtils.clone(buttonBaseGlb.scene)
  }, [buttonBaseGlb.scene])
  const clonedProgressCylinderScene = useMemo(() => {
    return SkeletonUtils.clone(progressCylinderGlb.scene)
  }, [progressCylinderGlb.scene])

  useEffect(() => {
    // clonedProgressBarScene.traverse((child) => {
    //   const mesh = child as THREE.Mesh
    //   if (mesh.isMesh) {
    //     // enable shadows for all mesh children
    //     mesh.castShadow = true
    //     mesh.receiveShadow = true

    //     // if (mesh.material && 'color' in mesh.material) {
    //     //   mesh.material = mesh.material.clone()
    //     //   mesh.material.color.set('#7778CE')
    //     // }
    //   }
    // })

    // clonedProgressCylinderScene.traverse((child) => {
    //   const mesh = child as THREE.Mesh
    //   if (mesh.isMesh) {
    //     // enable shadows for all mesh children
    //     mesh.castShadow = true
    //     mesh.receiveShadow = true

    //     // if (mesh.material && 'color' in mesh.material) {
    //     //   mesh.material = mesh.material.clone()
    //     //   mesh.material.color.set('#7778CE')
    //     // }
    //   }
    // })

    // clonedButtonBaseScene.traverse((child) => {
    //   const mesh = child as THREE.Mesh
    //   if (mesh.isMesh) {
    //     // enable shadows for all mesh children
    //     mesh.castShadow = true
    //     mesh.receiveShadow = true

    //     // if (mesh.material && 'color' in mesh.material) {
    //     //   mesh.material = mesh.material.clone()
    //     //   mesh.material.color.set('#AAA8DB')
    //     // }
    //   }
    // })

    // progressBar animation
    if (!progressBarGlb.animations || progressBarGlb.animations.length === 0) {
      console.info('No animations found in the GLB file.')
      return
    }

    const progressAnimationClip = progressBarGlb.animations[0] // assuming only one animation

    const filteredTracks = progressAnimationClip.tracks.filter((track) => desiredTrackNames.includes(track.name))
    if (filteredTracks.length === 0) {
      console.info('No matching tracks found for the provided desiredTrackNames.')
      return
    }

    const progressBarMixer = new THREE.AnimationMixer(clonedProgressBarScene)
    progressBarMixerRef.current = progressBarMixer

    const newClip = new THREE.AnimationClip(
      progressAnimationClip.name + '_filtered',
      progressAnimationClip.duration,
      filteredTracks
    )
    const action = progressBarMixer.clipAction(newClip)
    action.clampWhenFinished = true // keep the animation at its final frame when done
    action.setLoop(THREE.LoopOnce, 1) // don't loop the animation
    action.reset().play()

    // click animation
    const buttonBaseMixer = new THREE.AnimationMixer(clonedButtonBaseScene)
    buttonBaseMixerRef.current = buttonBaseMixer

    buttonBaseGlb.animations.forEach((clip) => {
      const action = buttonBaseMixer.clipAction(clip)
      action.clampWhenFinished = false // reset state to initial when finished
      action.setLoop(THREE.LoopOnce, 1) // don't loop the animation
    })

    const progressCylinderMixer = new THREE.AnimationMixer(clonedProgressCylinderScene)
    progressCylinderMixerRef.current = progressCylinderMixer

    progressCylinderGlb.animations.forEach((clip) => {
      const action = progressCylinderMixer.clipAction(clip)
      action.clampWhenFinished = false // reset state to initial when finished
      action.setLoop(THREE.LoopOnce, 1) // don't loop the animation
    })

    return () => {
      progressBarMixer.stopAllAction()
      progressBarMixer.uncacheClip(newClip)

      buttonBaseMixer.stopAllAction()
      progressCylinderMixer.stopAllAction()
    }
  }, [
    clonedButtonBaseScene,
    clonedProgressBarScene,
    clonedProgressCylinderScene,
    buttonBaseGlb.animations,
    progressBarGlb.animations,
    progressCylinderGlb.animations
  ])

  const handleClick = () => {
    if (clickedRef.current) {
      return
    }

    clickedRef.current = true

    if (buttonBaseMixerRef.current && progressCylinderMixerRef.current) {
      // play each animation clip once and stop after the first cycle
      buttonBaseGlb.animations.forEach((clip) => {
        const action = buttonBaseMixerRef.current!.clipAction(clip)
        if (!isPlaying) {
          action.reset().play() // reset to the start of the animation and play
          action.paused = false // ensure the action is not paused
        }
      })

      progressCylinderGlb.animations.forEach((clip) => {
        const action = progressCylinderMixerRef.current!.clipAction(clip)
        if (!isPlaying) {
          action.reset().play() // reset to the start of the animation and play
          action.paused = false // ensure the action is not paused
        }
      })

      setIsPlaying(true)

      onAnchorClick()
    }

    // reset the flag after a short delay to allow future clicks
    callTimer(() => {
      clickedRef.current = false
    })
  }

  useEffect(() => {
    if (buttonBaseMixerRef.current && progressCylinderMixerRef.current && isPlaying) {
      // listen for the end of the animation and stop the animation when it completes its loop
      buttonBaseMixerRef.current.addEventListener('finished', () => {
        setIsPlaying(false)
      })

      progressCylinderMixerRef.current.addEventListener('finished', () => {
        setIsPlaying(false)
      })
    }
  }, [isPlaying])

  useFrame((_, delta) => {
    if (progressBarMixerRef.current) {
      progressBarMixerRef.current.update(delta)
    }

    // IMPORTANT - update the animation `delta` while it's playing
    if (buttonBaseMixerRef.current && isPlaying) {
      buttonBaseMixerRef.current.update(delta)
    }

    // IMPORTANT - update the animation `delta` while it's playing
    if (progressCylinderMixerRef.current && isPlaying) {
      progressCylinderMixerRef.current.update(delta)
    }
  })

  // multiplying the positions by 1.001 slightly increases the distance of the ActionAnchorModel from the sphere’s center, bringing it closer to the camera
  return (
    <group
      position={[position[0] * 1.001, position[1] * 1.001, position[2] * 1.001]}
      rotation={rotation}
      scale={[0.7, 0.7, 0.7]}
      onClick={handleClick}
    >
      <primitive object={clonedProgressBarScene} />
      <primitive object={clonedButtonBaseScene} />
      <primitive object={clonedProgressCylinderScene} />
    </group>
  )
})
