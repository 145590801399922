import { Fragment, useEffect, useMemo, useRef } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import { MotionValue } from 'framer-motion'
import sample from 'lodash/sample'
import * as THREE from 'three'

import {
  MIN_ROTATION_X,
  PATH_ASSETS_LEFT,
  PATH_ASSETS_RIGHT,
  ROTATION_INERTIA_DECAY,
  ROTATION_MAX_VELOCITY,
  ROTATION_MIN_THRESHOLD,
  ROTATION_SCROLLING_SPEED,
  ROTATION_TOUCH_SPEED,
  SPHERE_RADIUS,
  TOTAL_NUMBER_OF_ANCHORS
} from '../constants'
import { AnchorPosition } from '../types'
import { calculateRotationFromNormalVector, clampRotationX, generateRandomSequence } from '../utils'
import { ActionAnchorModel } from './ActionAnchorModel'
import { PNGModel } from './PNGModel'
import { SphereObject } from './SphereObject'

type RotatingGroupProps = {
  anchorPositions: AnchorPosition[]
  onAnchorClick: () => void
  scrollPosition: MotionValue<number>
}

export const RotatingGroup = ({ anchorPositions, onAnchorClick, scrollPosition }: RotatingGroupProps) => {
  const rotationVelocity = useRef(0)
  const touchStartY = useRef(0)
  const groupRef = useRef<THREE.Group>(null)

  const { gl } = useThree()

  useEffect(() => {
    if (groupRef.current) {
      groupRef.current.rotation.x = MIN_ROTATION_X
    }
  }, [])

  useEffect(() => {
    const canvas = gl.domElement

    const handleWheel = (event: WheelEvent) => {
      event.preventDefault()
      const deltaRotation = event.deltaY * ROTATION_SCROLLING_SPEED
      rotationVelocity.current += deltaRotation
      rotationVelocity.current = THREE.MathUtils.clamp(
        rotationVelocity.current,
        -ROTATION_MAX_VELOCITY,
        ROTATION_MAX_VELOCITY
      )
    }

    const handleTouchStart = (event: TouchEvent) => {
      touchStartY.current = event.touches[0].clientY
    }

    const handleTouchMove = (event: TouchEvent) => {
      event.preventDefault()
      const deltaY = event.touches[0].clientY - touchStartY.current
      const deltaRotation = deltaY * ROTATION_TOUCH_SPEED
      rotationVelocity.current += deltaRotation
      rotationVelocity.current = THREE.MathUtils.clamp(
        rotationVelocity.current,
        -ROTATION_MAX_VELOCITY,
        ROTATION_MAX_VELOCITY
      )
      touchStartY.current = event.touches[0].clientY
    }

    canvas.addEventListener('wheel', handleWheel, { passive: false })
    canvas.addEventListener('touchstart', handleTouchStart, { passive: false })
    canvas.addEventListener('touchmove', handleTouchMove, { passive: false })

    return () => {
      canvas.removeEventListener('wheel', handleWheel)
      canvas.removeEventListener('touchstart', handleTouchStart)
      canvas.removeEventListener('touchmove', handleTouchMove)
    }
  }, [gl])

  const applyRotationVelocity = () => {
    if (groupRef.current) {
      // apply the current velocity to the group’s rotation
      const newRotationX = groupRef.current.rotation.x + rotationVelocity.current
      const clampedRotation = clampRotationX(newRotationX)
      groupRef.current.rotation.x = clampedRotation
      scrollPosition.set(clampedRotation)
    }
  }

  useFrame(() => {
    applyRotationVelocity()

    // decay the rotation velocity by multiplying it with INERTIA_DECAY to simulate a friction, causing the rotation to slow down over time
    rotationVelocity.current *= ROTATION_INERTIA_DECAY
    // this conditional check ensures that very small velocities are set to zero to prevent endless micro-rotations
    if (Math.abs(rotationVelocity.current) < ROTATION_MIN_THRESHOLD) {
      rotationVelocity.current = 0
    }
  })

  // instead of object creation in loop (because is expensive) we create a single object Vector3 instead and use vector.set()
  const tempOriginalPosition = new THREE.Vector3(0, 0, 0)
  const tempSphericalPosition = new THREE.Vector3(0, 0, 0)
  const tempAdjustedPosition_1 = new THREE.Vector3(0, 0, 0)
  const tempAdjustedPosition_2 = new THREE.Vector3(0, 0, 0)

  const randomPositionX = useMemo(() => {
    return generateRandomSequence(TOTAL_NUMBER_OF_ANCHORS)
  }, [])

  const componentsOnThePath = useMemo(() => {
    return anchorPositions.map((button, index) => {
      tempOriginalPosition.set(...button.position)

      tempAdjustedPosition_1.set(randomPositionX[index][0], tempOriginalPosition.y, tempOriginalPosition.z)
      tempAdjustedPosition_2.set(randomPositionX[index][1], tempOriginalPosition.y, tempOriginalPosition.z)

      // normalize the adjusted position to lie on the sphere's surface
      tempAdjustedPosition_1.normalize().multiplyScalar(SPHERE_RADIUS)
      tempAdjustedPosition_2.normalize().multiplyScalar(SPHERE_RADIUS)

      // calculate the rotation based on the unperturbed spherical position
      tempSphericalPosition.set(0, tempOriginalPosition.y, tempOriginalPosition.z) // ignore x for rotation
      const adjustedRotation = calculateRotationFromNormalVector(tempSphericalPosition.normalize())

      const randomPathAsset_1 = sample(PATH_ASSETS_LEFT)
      const randomPathAsset_2 = sample(PATH_ASSETS_RIGHT)

      // 0 - remove the first anchor (end of path)
      return index === 0 ? null : (
        <Fragment key={`item-${index}`}>
          <PNGModel
            position={tempAdjustedPosition_1.toArray()}
            rotation={adjustedRotation}
            path={randomPathAsset_1.path}
            surfaceOffset={randomPathAsset_1.surfaceOffset}
            width={randomPathAsset_1.width}
            height={randomPathAsset_1.height}
          />

          <ActionAnchorModel position={button.position} rotation={button.rotation} onAnchorClick={onAnchorClick} />

          <PNGModel
            position={tempAdjustedPosition_2.toArray()}
            rotation={adjustedRotation}
            path={randomPathAsset_2.path}
            surfaceOffset={randomPathAsset_2.surfaceOffset}
            width={randomPathAsset_2.width}
            height={randomPathAsset_2.height}
          />
        </Fragment>
      )
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorPositions])

  return (
    <group ref={groupRef} position={[0, -40, 0]}>
      <SphereObject />

      {componentsOnThePath}
    </group>
  )
}
