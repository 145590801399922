import * as THREE from 'three'

/** Radius of the spherical object */
export const SPHERE_RADIUS = 50

/** number off all anchor buttons on whole map */
export const TOTAL_NUMBER_OF_ANCHORS = 75

/** sinusoid parameters */
export const MAIN_PATH_AMPLITUDE = 0.75
export const MAIN_PATH_FREQUENCY = 30

/** the speed of scrolling */
export const ROTATION_SCROLLING_SPEED = 0.00005
/** the speed of touch actions */
export const ROTATION_TOUCH_SPEED = 0.0005
/** 0 (no movement) - 1 (no decay) */
export const ROTATION_INERTIA_DECAY = 0.95
/** maximum rotation speed */
export const ROTATION_MAX_VELOCITY = 0.05
/** very small velocities threshold */
export const ROTATION_MIN_THRESHOLD = 0.0001

/** The number of segments in a sphere object — higher values result in better quality, but increase the difficulty for the hardware to render. */
export const NUMBER_OF_SEGMENTS = 64

/** In pixels */
export const PATH_END_OFFSET = 64

/** Initial rotation of sphere */
export const MIN_ROTATION_X = 0.97 // initial rotation angle (in radians)
export const MAX_ROTATION_X = 2 * Math.PI + MIN_ROTATION_X / 2 // max rotation corresponds to 1 full circles (2π radians)

/** Assets along the road */
export const PATH_ASSETS_LEFT = [
  {
    path: 'cartoon/bag.png',
    surfaceOffset: 1.3,
    width: 1.5,
    height: 2
  },
  {
    path: 'cartoon/brush_1.png',
    surfaceOffset: 0.9,
    width: 2.5,
    height: 4
  },
  {
    path: 'cartoon/brush_2.png',
    surfaceOffset: 0.9,
    width: 1.5,
    height: 1.5
  },
  {
    path: 'cartoon/bus.png',
    surfaceOffset: 1.2,
    width: 3,
    height: 3
  },
  {
    path: 'cartoon/stone.png',
    surfaceOffset: 0.9,
    width: 2,
    height: 1.5
  }
]

export const PATH_ASSETS_RIGHT = [
  {
    path: 'cartoon/bag.png',
    surfaceOffset: 1.3,
    width: 1.5,
    height: 2
  },
  {
    path: 'cartoon/brush_1.png',
    surfaceOffset: 0.9,
    width: 2.5,
    height: 4
  },
  {
    path: 'cartoon/brush_2.png',
    surfaceOffset: 0.9,
    width: 1.5,
    height: 1.5
  },
  {
    path: 'cartoon/ghost.png',
    surfaceOffset: 2,
    width: 2,
    height: 2
  },
  {
    path: 'cartoon/stone.png',
    surfaceOffset: 0.9,
    width: 2,
    height: 1.5
  }
]

// skip the first X points of the tube
const TUBE_START = 16 / 1000
// skip the end X points of the tube (phi from reaching exactly 2π to avoid looping back to the start)
const TUBE_END = 0.988

// Custom sinusoidal curve class
export class SinusoidalCurve3 extends THREE.Curve<THREE.Vector3> {
  amplitude: number
  frequency: number
  radius: number

  constructor(amplitude: number, frequency: number, radius: number) {
    super()
    this.amplitude = amplitude
    this.frequency = frequency
    this.radius = radius
  }

  getPoint(t: number): THREE.Vector3 {
    const phi = (TUBE_START + t * (1 - TUBE_START)) * 2 * Math.PI * TUBE_END

    const x = this.amplitude * Math.sin(this.frequency * phi)
    const y = this.radius * Math.cos(phi)
    const z = this.radius * Math.sin(phi)

    // ensure the point lies on the sphere's surface
    return new THREE.Vector3(x, y, z).normalize().multiplyScalar(this.radius)
  }
}
