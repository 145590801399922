import { Pages } from '@app/config/router/Pages'
import { initApolloClient } from '@app/modules/common/actions/apolloActions'

import { authStore } from '../store/authStore'
import { tokenStore } from '../store/tokenStore'
import { addUnauthorizedHandlerToAxios } from './axiosActions'

export const initAuth = () => {
  addUnauthorizedHandlerToAxios(logout, getAccessToken)
  initApolloClient()
}

export const login = () => {
  const kc = authStore.useStore.getState().keycloak

  if (!kc) {
    throw new Error('KeyCloak not yet initialized')
  }

  return kc.login()
}

export const logout = () => {
  const kc = authStore.useStore.getState().keycloak

  if (!kc) {
    throw new Error('KeyCloak not yet initialized')
  }

  tokenStore.clearStore()

  return kc.logout({ redirectUri: window.location.origin })
}

export const getAccessToken = async (minValidity = 30): Promise<void> => {
  const kc = authStore.useStore.getState().keycloak

  if (!kc) {
    throw new Error('KeyCloak not yet initialized')
  }

  await kc.updateToken(minValidity)
}

export const redirectToAccountManagement = async () => {
  const kc = authStore.useStore.getState().keycloak

  if (!kc) {
    throw new Error('KeyCloak not yet initialized')
  }

  await kc.accountManagement()
}

export const redirectToRegister = async () => {
  const kc = authStore.useStore.getState().keycloak

  if (!kc) {
    throw new Error('KeyCloak not yet initialized')
  }

  await kc.register({ redirectUri: window.location.origin + Pages.DASHBOARD })
}

export const getKeyCloak = () => {
  return authStore.useStore.getState().keycloak
}
