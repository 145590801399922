import Confetti from './lottie/celebration/confetti.json'
import RecordingDots from './lottie/lesson/analyze.json'
import New_CS from './lottie/lesson/new_cs.json'
import New_EN from './lottie/lesson/new_en.json'

export const LottieContent = {
  celebration: {
    Confetti
  },

  flashcard: {
    recordingDots: RecordingDots,
    newLabel: {
      en: New_EN,
      cs: New_CS
    }
  } as const
}
