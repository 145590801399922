import * as THREE from 'three'

import { MAX_ROTATION_X, MIN_ROTATION_X } from './constants'

const up = new THREE.Vector3(0, 1, 0)

// calculate the rotation from normal vector
export const calculateRotationFromNormalVector = (normal: THREE.Vector3): THREE.Euler => {
  // check if the normal is opposite to the up vector
  if (normal.dot(up) < -0.9999) {
    // if they are nearly opposite, use an alternative axis
    return new THREE.Euler(Math.PI, 0, 0)
  }

  const quaternion = new THREE.Quaternion()
  quaternion.setFromUnitVectors(up, normal)
  return new THREE.Euler().setFromQuaternion(quaternion)
}

export const clampRotationX = (rotationX: number) => Math.max(MIN_ROTATION_X, Math.min(rotationX, MAX_ROTATION_X))

export const generateRandomSequence = (count: number): number[][] => {
  let previousFirst: number | null = null
  let previousSecond: number | null = null
  const sequence: number[][] = []

  for (let i = 0; i < count; i++) {
    let first: number
    let second: number

    // generate a random number between [-5.5, -3] for the first position
    do {
      first = parseFloat(randomInRange(-5.5, -3).toFixed(1))
    } while (first === previousFirst)

    // generate a random number between [3, 5.5] for the second position
    do {
      second = parseFloat(randomInRange(3, 5.5).toFixed(1))
    } while (second === previousSecond)

    sequence.push([first, second])

    previousFirst = first
    previousSecond = second
  }

  return sequence
}

function randomInRange(min: number, max: number): number {
  return Math.random() * (max - min) + min
}
