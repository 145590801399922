import { useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useWindowSize } from 'react-use'
import { useOrientation } from 'react-use'
import { motion, MotionValue, useInView, useMotionValue } from 'framer-motion'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'

import { profilePicturesContent } from '@foxino/components-common'

import { StudentRank } from '@app/data'
import { useTranslation } from '@app/locales'
import { getFullName } from '@app/modules/auth/utils/getFullName'

import { RanksDataType } from '../../../model/enums'

export const PLAYER_ITEM_HEIGHT = 56

type RestPlayersListProps = {
  isReady: boolean
  dataType: RanksDataType
  setDataType: (dataType: RanksDataType) => void
  playersForWeek: StudentRank[]
  playersForMonth: StudentRank[]
  playersOverall: StudentRank[]
  numOfListPlayers: number
  dragConstraints: any
  minListHeight: number
  y: MotionValue<number>
}

export const RestPlayersList = ({
  isReady,
  dataType,
  setDataType,
  playersForWeek,
  playersForMonth,
  playersOverall,
  numOfListPlayers,
  dragConstraints,
  minListHeight,
  y
}: RestPlayersListProps) => {
  const { t } = useTranslation('dashboard')
  const state = useOrientation()

  const [drag, setDrag] = useState<boolean | 'x' | 'y' | undefined>()
  const [swiper, setSwiper] = useState<SwiperClass>()

  const { height: windowHeight } = useWindowSize()

  const amIInTheListForWeek = playersForWeek.find((item) => item.currentStudent) != null
  const amIInTheListForMonth = playersForMonth.find((item) => item.currentStudent) != null
  const amIInTheListOverall = playersOverall.find((item) => item.currentStudent) != null

  useEffect(() => {
    if (isReady) {
      // enable the dragging only when everything is ready
      setDrag('y')
    }
  }, [isReady])

  useEffect(() => {
    if (dataType === RanksDataType.WEEK) {
      if (swiper?.activeIndex !== 0) swiper?.slideTo(0)
    } else if (dataType === RanksDataType.MONTH) {
      if (swiper?.activeIndex !== 1) swiper?.slideTo(1)
    } else if (dataType === RanksDataType.OVERALL) {
      if (swiper?.activeIndex !== 2) swiper?.slideTo(2)
    }
  }, [dataType, swiper])

  const handleSlideChange = () => {
    if (swiper?.activeIndex === 0) {
      setDataType(RanksDataType.WEEK)
    } else if (swiper?.activeIndex === 1) {
      setDataType(RanksDataType.MONTH)
    } else if (swiper?.activeIndex === 2) {
      setDataType(RanksDataType.OVERALL)
    }
  }

  return (
    <>
      <motion.div
        style={{
          y, // motion variable,
          minHeight: minListHeight
        }}
        className="relative h-full w-full rounded-t-2xl bg-btn-primary-outline-bg-gradient p-2"
        drag={drag}
        dragConstraints={dragConstraints}
        dragElastic={{ top: 0, bottom: 0.5 }}
        dragTransition={{ power: 0.2 }}
        initial={{ y: windowHeight }}
        animate={{ y: isMobile && state.type === 'landscape-primary' ? windowHeight : dragConstraints.bottom }}
        transition={{ duration: 0.5, type: 'spring' }}
      >
        <Swiper
          onSlideChange={handleSlideChange}
          onSwiper={(swiper) => setSwiper(swiper)}
          preventClicks={true}
          preventClicksPropagation={true}
          preventInteractionOnTransition={true}
        >
          <SwiperSlide>
            {playersForWeek.length === 0 ? (
              <div style={{ height: minListHeight }} className="flex w-full flex-col gap-5 p-4">
                <div className="text-center text-[20px] font-bold text-[#5B6C9B]">{t('leaderBoard.noResults')}</div>
                <div className="text-center font-light text-[#5B6C9B]/80">{t('leaderBoard.noResultsDescription')}</div>
              </div>
            ) : (
              playersForWeek.map((player, index) => {
                return <PlayerItem key={player.id} player={player} index={index} />
              })
            )}

            {amIInTheListForWeek && (
              <OverlayPlayerItem
                player={playersForWeek.find((item) => item.currentStudent)!}
                index={playersForWeek.findIndex((item) => item.currentStudent)}
                numOfListPlayers={numOfListPlayers}
                listHeight={isMobile && state.type === 'landscape-primary' ? windowHeight : dragConstraints.bottom}
                y={y}
              />
            )}
          </SwiperSlide>

          <SwiperSlide>
            {playersForMonth.length === 0 ? (
              <div style={{ height: minListHeight }} className="flex w-full flex-col gap-8 p-4">
                <div className="text-center text-[20px] font-bold text-[#5B6C9B]">{t('leaderBoard.noResults')}</div>
                <div className="text-center font-light text-[#5B6C9B]/80">{t('leaderBoard.noResultsDescription')}</div>
              </div>
            ) : (
              playersForMonth.map((player, index) => {
                return <PlayerItem key={player.id} player={player} index={index} />
              })
            )}

            {amIInTheListForMonth && (
              <OverlayPlayerItem
                player={playersForMonth.find((item) => item.currentStudent)!}
                index={playersForMonth.findIndex((item) => item.currentStudent)}
                numOfListPlayers={numOfListPlayers}
                listHeight={isMobile && state.type === 'landscape-primary' ? windowHeight : dragConstraints.bottom}
                y={y}
              />
            )}
          </SwiperSlide>

          <SwiperSlide>
            {playersOverall.length === 0 ? (
              <div style={{ height: minListHeight }} className="flex w-full flex-col gap-8 p-4">
                <div className="text-center text-[20px] font-bold text-[#5B6C9B]">{t('leaderBoard.noResults')}</div>
                <div className="text-center font-light text-[#5B6C9B]/80">{t('leaderBoard.noResultsDescription')}</div>
              </div>
            ) : (
              playersOverall.map((player, index) => {
                return <PlayerItem key={player.id} player={player} index={index} />
              })
            )}

            {amIInTheListOverall && (
              <OverlayPlayerItem
                player={playersOverall.find((item) => item.currentStudent)!}
                index={playersOverall.findIndex((item) => item.currentStudent)}
                numOfListPlayers={numOfListPlayers}
                listHeight={isMobile && state.type === 'landscape-primary' ? windowHeight : dragConstraints.bottom}
                y={y}
              />
            )}
          </SwiperSlide>
        </Swiper>
      </motion.div>
    </>
  )
}

type PlayerItemProps = {
  player: StudentRank
  index: number
}

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 }
}

const PlayerItem = ({ player, index }: PlayerItemProps) => {
  const { t } = useTranslation('common')

  const ref = useRef(null)
  const isInView = useInView(ref, { margin: `56px`, once: true })

  const { backgroundColor, image } = profilePicturesContent[player.student.userProfile.pictureUrl || 'image1']
  const rowBg = player.currentStudent ? 'linear-gradient(91deg, #D3C3FF 0%, #CEBDFF 100%)' : 'transparent'
  const textStyle = player.currentStudent ? 'text-[#7164E9]' : 'text-[#5B6C9B]'

  return (
    <motion.div
      ref={ref}
      style={{ background: rowBg, height: PLAYER_ITEM_HEIGHT }}
      className={`flex w-full flex-row justify-between rounded-2xl px-2 text-right text-base font-extrabold antialiased ${textStyle}`}
      initial="hidden"
      animate={isInView ? 'visible' : 'hidden'}
      variants={itemVariants}
      transition={{ duration: 0.25 }}
    >
      <div className="flex flex-row items-center gap-2">
        <span className="w-[24px]">{index + 4}.</span>
        <div className="h-10 w-10 rounded-full p-1" style={{ backgroundColor }}>
          <img className="pointer-events-none h-full w-full" src={image} />
        </div>
        <span>{getFullName(player.student.userProfile.firstName, player.student.userProfile.lastName)}</span>
      </div>

      <div className="flex flex-row items-center justify-center gap-1 text-lg">
        {(player?.points || 0).toLocaleString()} {t('point')}
      </div>
    </motion.div>
  )
}

type OverlayPlayerItemProps = PlayerItemProps & {
  numOfListPlayers: number
  listHeight: number
  y: MotionValue<number>
}

const OverlayPlayerItem = ({ player, index, numOfListPlayers, listHeight, y }: OverlayPlayerItemProps) => {
  const { t } = useTranslation('common')

  const ref = useRef(null)

  const overlayPlayerItemTop = useMotionValue(0)
  const overlayPlayerItemOpacity = useMotionValue(1)

  const onListDragYUpdate = (latest: number) => {
    overlayPlayerItemTop.set((numOfListPlayers - 1) * PLAYER_ITEM_HEIGHT + listHeight - latest)

    if (latest > (numOfListPlayers - 1) * PLAYER_ITEM_HEIGHT + listHeight - latest + (index - 1) * PLAYER_ITEM_HEIGHT) {
      overlayPlayerItemOpacity.set(1)
    } else {
      overlayPlayerItemOpacity.set(0)
    }
  }

  useEffect(() => {
    const unsubscribeListDragY = y.on('change', onListDragYUpdate)

    return () => {
      unsubscribeListDragY()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [y, listHeight])

  const { backgroundColor, image } = profilePicturesContent[player.student.userProfile.pictureUrl || 'image1']

  return (
    <motion.div
      ref={ref}
      style={{
        position: 'absolute',
        background: 'linear-gradient(91deg, #D3C3FF 0%, #CEBDFF 100%)',
        height: PLAYER_ITEM_HEIGHT,
        top: overlayPlayerItemTop, // motion variable
        opacity: overlayPlayerItemOpacity // motion variable
      }}
      className="flex w-full flex-row justify-between rounded-2xl px-2 text-right text-base font-extrabold text-[#7164E9] antialiased"
    >
      <div className="flex flex-row items-center gap-2">
        <span className="w-[24px]">{index + 4}.</span>
        <div className="h-10 w-10 rounded-full p-1" style={{ backgroundColor }}>
          <img className="pointer-events-none h-full w-full" src={image} />
        </div>
        <span>{getFullName(player.student.userProfile.firstName, player.student.userProfile.lastName)}</span>
      </div>

      <div className="flex flex-row items-center justify-center gap-1 text-lg">
        {(player?.points || 0).toLocaleString()} {t('point')}
      </div>
    </motion.div>
  )
}
