import { useEffect } from 'react'
import * as SDK from 'microsoft-cognitiveservices-speech-sdk'

import Constants from '@app/constants'
import { cognitiveServicesStore } from '@app/modules/lesson/store'
import { logError } from '@app/utils/logsUtils'

// initialize sdk
SDK.Recognizer.enableTelemetry(false)
const speechConfig = SDK.SpeechConfig.fromSubscription(Constants.SPEECH_KEY, Constants.SPEECH_REGION)
const audioConfigFromMic = SDK.AudioConfig.fromDefaultMicrophoneInput()
const speechRecognizer = new SDK.SpeechRecognizer(speechConfig, audioConfigFromMic)

export type UseSttReturnType = {
  startRecognizing: () => void
  stopRecognizing: () => void
}

type Props = {
  locale: string
  recordDuration?: number
  startRecognizingCallback?: () => void
  stopRecognizingCallback?: () => void
}

/**
 * Real-time speech to text synthesis using MS Azure Speech service
 */
export const useStt = ({ locale, startRecognizingCallback, stopRecognizingCallback }: Props): UseSttReturnType => {
  const { phraseList, isRecognizing } = cognitiveServicesStore.useStore(({ phraseList, isRecognizing }) => ({
    phraseList,
    isRecognizing
  }))

  speechConfig.speechRecognitionLanguage = locale

  if (phraseList.length > 0) {
    // adding a phrase to a phrase list increases its importance, thus making it more likely to be recognized
    SDK.PhraseListGrammar.fromRecognizer(speechRecognizer).addPhrases(phraseList)
  }

  speechRecognizer.recognizing = (_s, e) => {
    console.info(`[recognizing]: ${e.result.text}`)
    cognitiveServicesStore.setRecognizedText(e.result.text)
  }

  speechRecognizer.recognized = (_s, e) => {
    if (e.result.reason === SDK.ResultReason.RecognizedSpeech) {
      console.info(`[recognized]: ${e.result.text}`)
      cognitiveServicesStore.setIsRecognized(true)
      cognitiveServicesStore.setRecognizedText(e.result.text)

      const recognizedTextMatch = phraseList.some(
        (pl) =>
          pl.replace('.', ' ').toLocaleLowerCase().trim() === e.result.text.replace('.', ' ').toLocaleLowerCase().trim()
      )

      if (recognizedTextMatch) {
        stopRecognizing()
      }
    } else if (e.result.reason === SDK.ResultReason.NoMatch) {
      console.info('[recognized]: NOMATCH - speech could not be recognized.')
    }
  }

  speechRecognizer.speechEndDetected = () => {
    console.info('[speechEndDetected]')
    stopRecognizing()
  }

  speechRecognizer.canceled = (_s, e) => {
    console.info(`[canceled]: Reason=${e.reason}`)

    if (e.reason === SDK.CancellationReason.Error) {
      logError(e, 'useStt', 'speechRecognizer.canceled')
      console.error(`[canceled]: ErrorCode=${e.errorCode}`)
      console.error(`[canceled]: ErrorDetails=${e.errorDetails}`)
    }

    stopRecognizing()
  }

  speechRecognizer.sessionStarted = () => {
    console.info('[sessionStarted]')
  }

  speechRecognizer.sessionStopped = () => {
    console.info('[sessionStopped]')
    stopRecognizing()
  }

  const startRecognizing = () => {
    try {
      cognitiveServicesStore.setIsRecognizing(true)
      cognitiveServicesStore.setIsRecognized(false)
      cognitiveServicesStore.setRecognizedText('')

      speechRecognizer.startContinuousRecognitionAsync(
        () => {
          console.info('[startRecognizing][startContinuousRecognitionAsync]')
          startRecognizingCallback?.()
        },
        (err) => console.error('[startRecognizing]: Error starting recognition', err)
      )
    } catch (error) {
      console.error('[startRecognizing]: Error starting recognition', error)
    }
  }

  const stopRecognizing = () => {
    try {
      if (isRecognizing) {
        cognitiveServicesStore.setIsRecognizing(false)

        speechRecognizer.stopContinuousRecognitionAsync(
          () => {
            console.info('[stopRecognizing][stopContinuousRecognitionAsync]')
            stopRecognizingCallback?.()
          },
          (err) => console.error('[stopRecognizing]: Error stopping recognition', err)
        )
      }
    } catch (error) {
      console.error('[stopRecognizing]: Error stopping recognition', error)
    }
  }

  useEffect(() => {
    return () => {
      stopRecognizing()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    startRecognizing,
    stopRecognizing
  }
}
