import { useEffect, useRef, useState } from 'react'
import PinField from 'react-pin-field'
import { useSearchParams } from 'react-router-dom'
import { useToggle } from 'react-use'
import { Scanner } from '@yudiel/react-qr-scanner'
import classNames from 'classnames'

import { useTimeout } from '@foxino/components-common'

import { SvgContent } from '@app/assets/SvgContent'
import { Text } from '@app/components'
import { useTranslation } from '@app/locales'
import { showToast } from '@app/utils/commonUtils'
import { logError } from '@app/utils/logsUtils'

import { InputTypeSwitch } from './InputTypeSwitch'

const CODE_PARAM = 'logincode'

type Props = {
  onCodeRecognized: (code: string) => Promise<boolean>
}

const LENGTH_OF_PIN = 6

export const QrCodeInput = ({ onCodeRecognized }: Props) => {
  const [isQrHandlingAllowed, setIsQRHandlingAllowed] = useState<boolean>(true)
  const [code, setCode] = useState<string>('')
  const isCodeValid = useRef(false)
  const ref = useRef<HTMLInputElement[]>([])
  const [searchParams] = useSearchParams()

  const { t } = useTranslation('login')
  const [isQrScan, toggle] = useToggle(false)
  const [callQrCodeResetTimeout] = useTimeout(1000)
  const [callSetCodeFromParams] = useTimeout(500)

  useEffect(() => {
    for (let index = 0; index < LENGTH_OF_PIN; index++) {
      ref.current[index].placeholder = (index + 1).toString()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (code.length === 0) {
      for (let index = 0; index < LENGTH_OF_PIN; index++) {
        ref.current[index].placeholder = (index + 1).toString()
      }
    } else if (code.length < LENGTH_OF_PIN) {
      for (let index = 0; index < LENGTH_OF_PIN; index++) {
        ref.current[index].placeholder = ''
      }
    } else if (code.length === LENGTH_OF_PIN) {
      setIsQRHandlingAllowed(false)
      handleOnConfirmCode()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code])

  useEffect(() => {
    if (searchParams.get(CODE_PARAM)) {
      const codeFromParam = searchParams.get(CODE_PARAM)?.trim()

      if (codeFromParam?.length === LENGTH_OF_PIN) {
        callSetCodeFromParams(() => setCode(codeFromParam))
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  const handleOnQrInputChange = (numericText: string) => {
    setCode(numericText)
  }

  const handleOnConfirmCode = async () => {
    if (isCodeValid.current) {
      return
    }

    const isSuccessful = await onCodeRecognized(code)

    if (isSuccessful) {
      isCodeValid.current = true
      setCode('')
      setIsQRHandlingAllowed(true)
    } else {
      logError(new Error('Login Code is not valid'), 'QrCodeInput', 'handleOnConfirmCode', t('loginQR.qrError'))

      callQrCodeResetTimeout(() => {
        setIsQRHandlingAllowed(true)
        setCode('')
      })
    }
  }

  const handleOnQrLoaded = (qrCode: string) => {
    if (!code && isQrHandlingAllowed) {
      const parsedCode = qrCode.includes(`?${CODE_PARAM}=`) ? qrCode.split(`?${CODE_PARAM}=`).at(-1)?.trim() : undefined

      if (parsedCode?.length === LENGTH_OF_PIN) {
        setCode(parsedCode)
      } else if (qrCode.length === LENGTH_OF_PIN) {
        setCode(qrCode)
      } else {
        showToast(t('loginQR.qrError'), { type: 'error' })
      }
    }
  }

  return (
    <div className="relative flex h-full w-full flex-col items-center justify-start pt-[64px]">
      {isQrScan ? (
        <div className="flex w-full flex-col items-center justify-center gap-4">
          <Text className="text-neutral50">{t('loginQR.scanDescription')}</Text>

          <div className="flex h-[350px] w-[350px] items-center justify-center overflow-hidden rounded-3xl">
            <Scanner
              constraints={{ facingMode: 'environment' }}
              onScan={(data) => handleOnQrLoaded(data[0].rawValue)}
              onError={(error) => {
                logError(error, 'QrCodeInput', 'handleOnQrLoaded', t('loginQR.scannerError'))
              }}
            />
          </div>
        </div>
      ) : (
        <div className="flex max-w-[480px] flex-col">
          <div className="flex w-full flex-row justify-between px-4">
            <PinField
              ref={ref}
              autoFocus
              className={classNames(
                'focus:border-transparent w-[60px] bg-[transparent] pl-[6px] text-[72px] text-neutral50 placeholder:text-neutral50/25 focus:outline-none focus:ring-0',
                'opacity-100'
              )}
              length={LENGTH_OF_PIN}
              inputMode="numeric"
              onPasteCapture={(e) => {
                e.preventDefault()

                const data = e.clipboardData.getData('text/plain').split(' ').join('').slice(0, LENGTH_OF_PIN)
                setCode(data)
              }}
              pattern="[0-9]*"
              validate={['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']}
              onChange={handleOnQrInputChange}
            />
          </div>

          <div className="-mt-10 flex w-full flex-row justify-between px-4">
            {Array.from({ length: LENGTH_OF_PIN }).map((_, index) => {
              return (
                <object
                  key={index}
                  className="w-[60px] object-contain"
                  type="image/svg+xml"
                  data={SvgContent.login.pinUnderline}
                />
              )
            })}
          </div>
        </div>
      )}
      <InputTypeSwitch isQrScan={isQrScan} toggle={toggle} />
    </div>
  )
}
