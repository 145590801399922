import { ReactNode, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { QrCode02 } from '@untitled-ui/icons-react'

import { Heading } from '@app/components'
import { Pages } from '@app/config/router/Pages'
import { useTranslation } from '@app/locales'
import { useStudentsRanks } from '@app/modules/ranks/data'
import { useStudentInfo } from '@app/modules/tasks/data'
import { isInStandAloneMode, isIOSWebView } from '@app/utils/commonUtils'

import { GoBackBtn } from '../GoBackBtn'

type Props = {
  className?: string
  isBackBtnVisible?: boolean
  isQrLoginBtnVisible?: boolean
  isXpBarVisible?: boolean
  onBackBtnClick?: () => void
  title?: ReactNode
}

export const MainHeader = ({
  className,
  isBackBtnVisible = false,
  isQrLoginBtnVisible = false,
  isXpBarVisible = false,
  onBackBtnClick,
  title = ''
}: Props) => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()

  const { studentInfo } = useStudentInfo()
  const { overallRanks } = useStudentsRanks(studentInfo?.student.classrooms[0]?.id)
  const totalPoints = overallRanks.find((rank) => rank.currentStudent)?.points || 0

  const handleOnBackClick = () => {
    onBackBtnClick ? onBackBtnClick() : navigate(-1)
  }

  const handleOpenSocialActivityLogin = () => {
    navigate(Pages.SOCIAL_ACTIVITY_LOGIN)
  }

  useEffect(() => {
    if (!isInStandAloneMode() && !isIOSWebView()) {
      const header = document.querySelector('header')
      if (header) {
        header.style.paddingTop = '16px'
      }
    }
  }, [])

  return (
    <header className={classNames('relative flex w-full flex-row items-center justify-between px-4 pt-2', className)}>
      <div className="flex h-[48px] flex-1 items-start">
        {isQrLoginBtnVisible && (
          <button className="h-12 w-12 text-neutral50" onClick={handleOpenSocialActivityLogin}>
            <QrCode02 />
          </button>
        )}

        {isBackBtnVisible && <GoBackBtn onClick={handleOnBackClick} />}
      </div>

      <div className="flex-2 flex h-[48px] w-[70%] items-center justify-center text-center">
        <Heading size="xs" className="text-neutral50">
          {title}
        </Heading>
      </div>

      <div className="flex h-[48px] flex-1 items-center justify-end">
        {isXpBarVisible && (
          <div className="flex h-full w-[100px] items-center justify-end md:w-[125px]">
            <span
              className="text-nowrap text-left font-[GhostKidAOEPro] text-[20px] text-fill-transparent"
              style={{
                background: 'linear-gradient(180deg, #FF9C41 41.7%, #FF8413 59.93%)',
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text'
              }}
            >
              {totalPoints.toLocaleString()} {t('point')}
            </span>
          </div>
        )}
      </div>
    </header>
  )
}
