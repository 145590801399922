import { createJSONStorage, persist } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

type TokenStoreType = {
  accessToken?: string
  refreshToken?: string
  isLoggedWithQR: boolean

  newRegistrationData?: {
    classroomId: string
    classroomLoginCode: string
  }
}

const initialState: TokenStoreType = {
  isLoggedWithQR: false
}

const useStore = createWithEqualityFn(
  persist<TokenStoreType>(() => initialState, {
    name: 'tokenStore',
    storage: createJSONStorage(() => sessionStorage)
  }),
  shallow
)

export const tokenStore = {
  saveAccessToken: (accessToken: string): void => {
    useStore.setState({ accessToken })
  },
  saveRefreshToken: (refreshToken: string): void => {
    useStore.setState({ refreshToken })
  },
  setIsLoggedWithQR: (isLoggedWithQR: boolean): void => {
    useStore.setState({ isLoggedWithQR })
  },

  setNewRegistrationData: (newRegistrationData: { classroomId: string; classroomLoginCode: string }): void => {
    useStore.setState({ newRegistrationData })
  },

  clearRegistrationData: (): void => {
    useStore.setState({ newRegistrationData: undefined })
  },

  clearStore: () => {
    useStore.setState(initialState, true)
  },

  useStore
}
