import { createJSONStorage, persist } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import Constants from '@app/constants'
import { InteractionType, LearningAssignmentType, NextFlashCardInput, Skill, Subject } from '@app/data'

type LessonInputsStoreType = {
  nextFlashCardInput: NextFlashCardInput
  timeLimitInSeconds?: number
  assignmentType?: LearningAssignmentType
  skills?: Skill[]
  startTimestamp?: number
  subject?: Subject
  canSpeak?: boolean
  canListen?: boolean
}

const initialState: LessonInputsStoreType = {
  nextFlashCardInput: {
    learningExecutionId: '',
    interactionTypes: [],
    supportedFlashCardAssignmentTypes: Constants.SUPPORTED_FLASHCARD_ASSIGNMENT_TYPES,
    supportedFlashCardResponseTypes: Constants.SUPPORTED_FLASHCARD_RESPONSE_TYPES,
    supportedFullScreenFlashCardTypes: Constants.SUPPORTED_FLASHCARD_FULLSCREEN_TYPES
  },
  assignmentType: undefined,
  subject: undefined,
  skills: []
}

const useStore = createWithEqualityFn(
  persist<LessonInputsStoreType>(() => initialState, {
    name: 'lessonInputsStore',
    storage: createJSONStorage(() => localStorage),
    merge: (persistedState, currentState) => {
      const newState = { ...currentState, ...(persistedState as LessonInputsStoreType) }
      // the persistedState might be corrupted and contain undefined values, initialize them again
      newState.nextFlashCardInput.interactionTypes ??= initialState.nextFlashCardInput.interactionTypes

      return newState
    }
  }),
  shallow
)

export const lessonInputsStore = {
  setNewLearningAssignment: (
    learningExecutionId: string,
    assignmentType: LearningAssignmentType,
    skills?: Skill[],
    timeLimitInSeconds?: number,
    subject?: Subject
  ): void => {
    useStore.setState((store) => {
      const previousListening = store.nextFlashCardInput.interactionTypes.includes(InteractionType.LISTENING)
      const previousSpeaking = store.nextFlashCardInput.interactionTypes.includes(InteractionType.SPEAKING)
      const nextFlashCardInput: Partial<NextFlashCardInput> = {
        interactionTypes: [
          ...(previousSpeaking ? [InteractionType.SPEAKING] : []),
          ...(previousListening ? [InteractionType.LISTENING] : [])
        ],
        learningExecutionId
      }

      return {
        assignmentType,
        skills,
        timeLimitInSeconds,
        nextFlashCardInput: { ...store.nextFlashCardInput, ...nextFlashCardInput },
        startTimestamp: undefined,
        canListen: previousListening,
        canSpeak: previousSpeaking,
        subject
      }
    })
  },
  setInteractionTypes: (canSpeak: boolean, canListen: boolean): void => {
    const interactionTypes: NextFlashCardInput['interactionTypes'] = [
      ...(canSpeak ? [InteractionType.SPEAKING] : []),
      ...(canListen ? [InteractionType.LISTENING] : [])
    ]

    useStore.setState((store) => ({
      nextFlashCardInput: { ...store.nextFlashCardInput, interactionTypes },
      canListen,
      canSpeak
    }))
  },

  setStartLessonTimestamp: (): void => {
    useStore.setState(() => ({
      startTimestamp: Date.now()
    }))
  },
  getIsTest: () => {
    return useStore.getState().assignmentType === LearningAssignmentType.TEST
  },

  clearStore: (): void => {
    useStore.setState(
      (store) => ({
        nextFlashCardInput: {
          ...store.nextFlashCardInput,
          learningExecutionId: ''
        }
      }),
      true
    )
  },
  useStore
}
