import { useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'

import { LoaderWrapper, Text } from '@app/components'
import { Pages } from '@app/config/router/Pages'
import { useTranslation } from '@app/locales'
import { MainHeader, NoClassSection } from '@app/modules/common'
import { useDashboardMenuHeight } from '@app/modules/common/layout/DashboardLayout/useDashboardMenuHeight'
import { lessonInputsStore } from '@app/modules/lesson/store'
import { isCurrentPathActive } from '@app/utils/commonUtils'
import { logError } from '@app/utils/logsUtils'

import { AllDoneForToday, TaskCardBig, TaskCardSmall } from '../components'
import { useActiveTasks, useFinishedTasks, useStudentInfo } from '../data'
import { LearningExecutionType } from '../model/types'

const REFRESH_INTERVAL = 15000

export const TasksPage = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { t } = useTranslation(['common', 'dashboard'])
  const refreshTimer = useRef<NodeJS.Timeout>(null)

  const bottomBarHeight = useDashboardMenuHeight()

  const { studentInfo } = useStudentInfo()

  const {
    activeTasks,
    error: activeTaskError,
    loading: activeTaskLoading,
    refetching: activeTaskRefetching,
    refetch: refetchActiveTasks
  } = useActiveTasks()
  const {
    finishedTasks,
    error: finishedTasksError,
    loading: finishedTasksLoading,
    hasMore: hasMoreFinishedTasks,
    refetching: finishedTasksRefetching,
    refetch: refetchFinishedTasks
  } = useFinishedTasks()

  const startRefreshTimer = () => {
    // @ts-ignore
    refreshTimer.current = setInterval(() => {
      refetchActiveTasks()
      refetchFinishedTasks()
    }, REFRESH_INTERVAL)
  }

  const stopRefreshTimer = () => {
    if (refreshTimer.current) {
      clearInterval(refreshTimer.current)
      // @ts-ignore
      refreshTimer.current = null
    }
  }

  useEffect(() => {
    if (isCurrentPathActive(pathname, Pages.DASHBOARD_TASKS, true) && !!(activeTasks || finishedTasks)) {
      refetchActiveTasks()
      refetchFinishedTasks()
    }

    // Add event listener for visibility change
    const handleVisibilityChange = () => {
      if (document.hidden) {
        stopRefreshTimer()
      } else {
        startRefreshTimer()
      }
    }

    startRefreshTimer()

    document.addEventListener('visibilitychange', handleVisibilityChange)

    // Cleanup event listener and timer on component unmount
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
      stopRefreshTimer()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  useEffect(() => {
    if (activeTaskError || finishedTasksError) {
      logError(activeTaskError || finishedTasksError, 'TasksPage', 'useEffect')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTaskError, finishedTasksError])

  const handleOnRunAssignmentClick = (
    { learningAssignment, id, remainingTimeSeconds }: LearningExecutionType,
    type: string
  ) => {
    // IMPORTANT - when starting a new learning assignment, we want to keep only the LISTENING interaction type,
    // because the user might have changed the SPEAKING permission settings in the meantime, so we cannot rely on the previous state
    // So we need to ask for the microphone permission every time the user starts a new learning assignment
    lessonInputsStore.setNewLearningAssignment(
      id,
      learningAssignment.type,
      learningAssignment.skills,
      remainingTimeSeconds ?? undefined,
      learningAssignment.subject
    )

    navigate(Pages.LESSON_BEFORE(type))
  }

  const handleOnShowMoreClick = () => {
    navigate(Pages.DASHBOARD_TASKS_FINISHED)
  }

  const handleOnFinishedTaskClick = ({ id }: LearningExecutionType, type: string) => {
    navigate(Pages.DASHBOARD_TASKS_DETAIL(id, type))
  }

  const loading = activeTaskLoading || activeTaskRefetching || finishedTasksLoading || finishedTasksRefetching

  if (!loading && studentInfo?.student.classrooms[0]?.id == null) {
    return <NoClassSection />
  }

  return (
    <div className="flex h-full w-full flex-col">
      <MainHeader isQrLoginBtnVisible isXpBarVisible />

      <LoaderWrapper
        loading={loading}
        error={
          (activeTaskError || finishedTasksError) != undefined &&
          activeTasks?.length === 0 &&
          finishedTasks?.length === 0
        }
        showChildrenOnLoading={!!activeTasks || !!finishedTasks}
      >
        <div style={{ paddingBottom: bottomBarHeight + 16 }} className="flex min-h-[300px] w-full flex-col">
          {activeTasks && activeTasks.length === 0 && <AllDoneForToday />}

          <motion.div
            className="flex flex-col gap-8 p-4 md:p-8"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            {activeTasks && activeTasks.length > 0 && (
              <div className="flex w-full flex-col gap-4">
                <span className="text-[20px] font-bold text-neutral50">{t('activities.active')}</span>
                <div className="md:grid md:grid-cols-2 xl:grid-cols-3">
                  <TaskCardBig task={activeTasks[0]} onClick={handleOnRunAssignmentClick} />
                </div>
                <div className="flex w-full flex-wrap justify-center gap-4 md:grid md:grid-cols-2 xl:grid-cols-3">
                  {activeTasks.slice(1, activeTasks.length).map((task, index) => {
                    return (
                      <TaskCardSmall
                        key={task.id}
                        isOdd={index % 2 !== 0}
                        task={task}
                        onInProgressTaskClick={handleOnRunAssignmentClick}
                      />
                    )
                  })}
                </div>
              </div>
            )}

            {finishedTasks && finishedTasks.length > 0 && (
              <div className="mb-16 flex w-full flex-wrap gap-4">
                <span className="text-[20px] font-bold text-neutral50">{t('activities.finished')}</span>
                <div className="flex w-full flex-wrap justify-center gap-4 md:grid md:grid-cols-2 xl:grid-cols-3">
                  {finishedTasks.map((task, index) => {
                    return (
                      <TaskCardSmall
                        key={task.id}
                        isOdd={index % 2 !== 0}
                        task={task}
                        onFinishedTaskClick={handleOnFinishedTaskClick}
                      />
                    )
                  })}
                </div>
                {hasMoreFinishedTasks && (
                  <Text
                    size="m"
                    className="mx-auto mt-6 cursor-pointer text-neutral100"
                    onClick={handleOnShowMoreClick}
                  >
                    {t('showMore')}
                  </Text>
                )}
              </div>
            )}
          </motion.div>
        </div>
      </LoaderWrapper>
    </div>
  )
}
