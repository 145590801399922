import { initReactI18next, useTranslation as i18UseTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import customParseFormatPlugin from 'dayjs/plugin/customParseFormat'
import isTodayPlugin from 'dayjs/plugin/isToday'
import relativeTimePlugin from 'dayjs/plugin/relativeTime'
import i18n, { TOptionsBase } from 'i18next'

import { localSettingsStore } from '@app/modules/common/store/localSettingsStore'

import * as CS_TRANSLATIONS from './cs-CZ'
import * as EN_TRANSLATIONS from './en-US'
import { appLanguageToCode, languageCodeToShort, ResourceLangType } from './types'

import 'dayjs/locale/cs'
import 'dayjs/locale/en'

dayjs.extend(customParseFormatPlugin)
dayjs.extend(relativeTimePlugin)
dayjs.extend(isTodayPlugin)

type TOptions = TOptionsBase & { [key: string]: string | number | undefined }

i18n
  .use(initReactI18next)
  .init(
    {
      load: 'currentOnly',
      compatibilityJSON: 'v3',
      lng: 'cs-CZ',
      supportedLngs: ['cs-CZ', 'en-US', 'dev'],
      simplifyPluralSuffix: false,
      pluralSeparator: '_',
      resources: {
        'en-US': EN_TRANSLATIONS,
        'cs-CZ': CS_TRANSLATIONS
      },
      defaultNS: 'common',
      fallbackNS: 'common',
      debug: import.meta.env.DEV,
      returnNull: false,
      interpolation: {
        escapeValue: false
      }
    },
    () => {
      const storedAppLang = localSettingsStore.useStore.getState().appLanguage
      const appLang = storedAppLang ? appLanguageToCode[storedAppLang] : 'cs-CZ'

      dayjs.locale(languageCodeToShort[appLang] || 'cs')
      i18n.changeLanguage(appLang).catch((error) => console.error('[i18n] changeLanguage error', error))

      return true
    }
  )
  .catch((error) => console.error('[i18n] init error', error))

export default i18n

export const useTranslation = (ns?: ResourceLangType | ResourceLangType[]) => {
  const { t: i18t } = i18UseTranslation()

  const storedAppLang = localSettingsStore.useStore.getState().appLanguage
  const appLang = storedAppLang ? appLanguageToCode[storedAppLang] : 'cs-CZ'

  const t = (i18nKey: string, options?: TOptions): string => {
    return i18t<string>(i18nKey, { ...options, ns, lng: appLang })
  }

  return { t }
}
